import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  Container: styled('div')({
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'column',
  }),
  TextContainer: styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: theme.breakpoints.values.md,
    marginBottom: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0px 10px 0px 10px',
    },
  })),
  Heading: styled(Typography)<TypographyProps>(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      fontSize: '28px',
    },
  })),
  Description: styled(Typography)<TypographyProps>(({ theme }) => ({
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  })),
};
