import { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';
import InnerHTML from 'dangerously-set-html-content';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { ThemeColorMap } from 'constants/themeColors';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface IHubspotFormProps {
  heading: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundImage?: string;
  sectionBackgroundGradient?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  hubspotCode: string;
}

export const HubspotFormProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundImage', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  { name: 'hubspotCode', type: 'longText' },
];

/**
 * Renders Hubspot Form
 *
 * @param {IHubspotFormProps} props
 * @return {ReactElement}
 */
export default function HubspotForm({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor = 'white',
  sectionBackgroundImage,
  sectionBackgroundGradient,
  sectionPaddingTop,
  sectionPaddingBottom,
  hubspotCode,
}: IHubspotFormProps): ReactElement {
  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgImage={sectionBackgroundImage}
      bgGradient={sectionBackgroundGradient}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      <StyledComponents.SectionContents>
        <div>
          <Typography
            variant="h3"
            sx={{
              color: ThemeColorMap.get(headingColor),
            }}
          >
            {heading}
          </Typography>
          {description && (
            <Typography
              variant="body1"
              sx={{
                color: ThemeColorMap.get(descriptionColor),
                marginTop: 16,
                whiteSpace: 'pre-line',
              }}
            >
              {description}
            </Typography>
          )}
        </div>
        <InnerHTML html={hubspotCode} />
      </StyledComponents.SectionContents>
    </SectionWrapper>
  );
}
