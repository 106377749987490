import { styled } from '@mui/material/styles';
import { Button, ButtonProps, Container, ContainerProps, Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  Root: styled('div')({
    position: 'relative',
    background: 'transparent',
  }),
  Container: styled(Container)<ContainerProps>({
    '&.MuiContainer-root': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  TextWrapper: styled('div')(({ theme }) => ({
    flexDirection: 'column',
    flex: 0.8,
    [theme.breakpoints.down('md')]: {
      flex: 0.9,
    },
  })),
  Title: styled(Typography)<TypographyProps>({
    marginBottom: 20,
  }),
  Description: styled(Typography)<TypographyProps>({
    marginBottom: 20,
    whiteSpace: 'pre-line',
  }),
  Button: styled(Button)<ButtonProps>({
    borderRadius: 8,
    width: 'fit-content',
    margin: 'auto',
    height: 50,
    padding: '15px 30px',
  }),
};
