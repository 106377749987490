import { ReactElement, useState } from 'react';
import { CardContent } from '@mui/material';
import Image from 'next/image';

import { ThemeColorMap } from 'constants/themeColors';
import { CMSThemeColors } from 'lib/theme';
import { StyledComponents } from './styled';

export interface IIconCardProps {
  headingLight?: string;
  headingLightColor?: CMSThemeColors;
  headingBold?: string;
  headingBoldColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  image?: string;
  alignment?: 'left' | 'center' | 'right';
  primaryButtonText?: string;
  primaryButtonLink?: string;
  backgroundColor?: CMSThemeColors;
  isCardVariantLarge?: boolean;
}

/**
 * Renders the IconCard component.
 *
 * @param {IconCardProps} props
 * @return {ReactElement}
 */
export default function IconCard({
  headingLight,
  headingLightColor = 'navy',
  headingBold,
  headingBoldColor = 'navy',
  description,
  descriptionColor = 'navy',
  image,
  alignment = 'center',
  primaryButtonText,
  primaryButtonLink,
  backgroundColor,
  isCardVariantLarge = true,
}: IIconCardProps): ReactElement {
  const [imageError, setImageError] = useState(false);

  return (
    <StyledComponents.IconCard backgroundColor={backgroundColor}>
      <CardContent>
        {image && (
          <StyledComponents.ImageContainer
            style={{
              width: isCardVariantLarge ? undefined : '108px',
              height: isCardVariantLarge ? undefined : '108px',
            }}
          >
            {imageError ? (
              <StyledComponents.DefaultImage />
            ) : (
              <Image
                layout="fill"
                src={image}
                onError={() => {
                  setImageError(true);
                }}
              />
            )}
          </StyledComponents.ImageContainer>
        )}
        {isCardVariantLarge && headingLight && (
          <StyledComponents.HeadingLight
            variant="h3"
            style={{
              textAlign: alignment,
              color: ThemeColorMap.get(headingLightColor),
            }}
          >
            {headingLight}
          </StyledComponents.HeadingLight>
        )}
        {headingBold && (
          <StyledComponents.HeadingBold
            variant={isCardVariantLarge ? 'h2' : 'h5'}
            sx={{
              textAlign: alignment,
              color: ThemeColorMap.get(headingBoldColor),
              textTransform: isCardVariantLarge ? 'uppercase' : 'none',
            }}
          >
            {headingBold}
          </StyledComponents.HeadingBold>
        )}
        {description && (
          <StyledComponents.Description
            variant="body1"
            sx={{
              textAlign: alignment,
              whiteSpace: 'pre-line',
              color: ThemeColorMap.get(descriptionColor),
            }}
          >
            {description}
          </StyledComponents.Description>
        )}
        {primaryButtonText && primaryButtonLink && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledComponents.Button
              size={isCardVariantLarge ? 'medium' : 'small'}
              variant="contained"
              color="primary"
              disableElevation
              href={primaryButtonLink}
            >
              {primaryButtonText}
            </StyledComponents.Button>
          </div>
        )}
      </CardContent>
    </StyledComponents.IconCard>
  );
}
