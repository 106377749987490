import { ReactElement } from 'react';
import { ContainerProps } from '@mui/material';

import { ThemeColorMap } from 'constants/themeColors';
import { StyledComponents } from './styled';

interface SectionWrapperProps {
  children: ContainerProps['children'];
  bgColor?: string;
  bgGradient?: string;
  bgImage?: string;
  paddingTop?: string;
  paddingBottom?: string;
  fullWidth?: boolean;
  className?: string;
}

/**
 * Renders Section Wrapper
 * @param {SectionWrapperProps} param0
 * @return {ReactElement}
 */
export default function SectionWrapper({
  children,
  bgColor,
  bgGradient,
  bgImage,
  paddingTop,
  paddingBottom,
  fullWidth = false,
  className,
}: SectionWrapperProps): ReactElement {
  const getBackgroundStyle = () => {
    if (bgImage) {
      return {
        backgroundImage: `url(${bgImage})`,
      };
    }

    if (bgGradient) {
      return {
        backgroundImage: bgGradient.replace(';', ''),
      };
    }

    return {
      backgroundColor: ThemeColorMap.get(bgColor || 'white'),
    };
  };

  return (
    <StyledComponents.Root
      className={className}
      style={{
        ...getBackgroundStyle(),
        paddingTop: paddingTop || 50,
        paddingBottom: paddingBottom || 50,
      }}
    >
      <StyledComponents.Container fullWidth={fullWidth}>{children}</StyledComponents.Container>
    </StyledComponents.Root>
  );
}
