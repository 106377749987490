import { styled } from '@mui/material/styles';
import { Typography, Button, TypographyProps, ButtonProps } from '@mui/material';

export const StyledComponents = {
  Card: styled('div')(({ theme }) => ({
    padding: '90px 40px 30px 40px',
    borderRadius: 12,
    border: 'solid 1px rgba(0, 148, 191, 0.25)',
    background: 'white',
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      padding: '80px 25px 45px 25px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '70px 15px 45px 15px',
    },
    height: '100% !important',
  })),
  Image: styled('div')(({ theme }) => ({
    position: 'absolute',
    width: 120,
    height: 120,
    left: '50%',
    top: -60,
    transform: 'translateX(-60px)',
    [theme.breakpoints.down('xl')]: {
      width: 100,
      height: 100,
      top: -50,
      transform: 'translateX(-50px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 80,
      height: 80,
      left: '50%',
      top: -40,
      transform: 'translateX(-40px)',
    },
    '& > img': {
      borderRadius: '50%',
    },
  })),
  Quote: styled(Typography)<TypographyProps>(({ theme }) => ({
    lineHeight: '1.33',
    marginBottom: 50,
    [theme.breakpoints.down('xl')]: {
      marginBottom: 40,
      fontSize: 22,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 30,
      fontSize: 20,
    },
  })),
  Description: styled(Typography)<TypographyProps>(({ theme }) => ({
    opacity: 0.8,
    marginBottom: 58,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('xl')]: {
      marginBottom: 48,
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 38,
      fontSize: 16,
    },
  })),
  Name: styled(Typography)<TypographyProps>(({ theme }) => ({
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.down('xl')]: {
      marginBottom: 16,
      margin: '8px !important',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 14,
      margin: '2px !important',
    },
  })),
  Company: styled(Typography)<TypographyProps>(({ theme }) => ({
    margin: '0px !important',
    fontSize: 18,
    [theme.breakpoints.down('xl')]: {
      marginBottom: 16,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 14,
    },
  })),
  Button: styled(Button)<ButtonProps>(({ theme }) => ({
    borderRadius: 10,
    marginTop: 32,
    height: 60,
    [theme.breakpoints.down('xl')]: {
      height: 50,
    },
    [theme.breakpoints.down('md')]: {
      height: 40,
    },
    '&:hover': {
      color: 'white',
    },
  })),
};
