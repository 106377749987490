import { ReactElement } from 'react';
import { Input } from '@builder.io/sdk/dist';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { StyledComponents } from './styled';

export interface IRichTextSectionProps {
  richText: string;
}

export const RichTextSectionProps: Input[] = [{ name: 'richText', type: 'richText', required: true }];

/**
 * Renders Rich Text Section
 *
 * @param {IRichTextSectionProps} props
 * @return {ReactElement}
 */
export default function RichTextSection({ richText }: IRichTextSectionProps): ReactElement {
  return (
    <SectionWrapper>
      <StyledComponents.Container dangerouslySetInnerHTML={{ __html: richText }}></StyledComponents.Container>
    </SectionWrapper>
  );
}
