import { Builder } from '@builder.io/react';
import AccordionSection, { AccordionSectionProps } from 'components/CMS/AccordionSection';
import AnimatedHeading, { AnimatedHeadingProps } from 'components/CMS/_Common/AnimatedHeading';
import BigVideoSection, { BigVideoSectionProps } from 'components/CMS/BigVideoSection';
import HeroBanner, { HeroBannerProps } from 'components/CMS/HeroBanner';
import HeroTextSection, { HeroTextSectionProps } from 'components/CMS/HeroTextSection';
import HotspotSection, { HotspotSectionProps } from 'components/CMS/HotspotSection';
import HubspotForm, { HubspotFormProps } from 'components/CMS/HubspotForm';
import IconCardsSection, { IconCardsSectionProps } from 'components/CMS/IconCardsSection';
import ImageCarousel, { ImageCarouselProps } from 'components/CMS/ImageCarousel';
import ImageSeparatorSection, { ImageSeparatorSectionProps } from 'components/CMS/ImageSeparatorSection';
import LogoBanner, { LogoBannerProps } from 'components/CMS/LogoBanner';
import PersonCardsSection, { PersonCardsSectionProps } from 'components/CMS/PersonCardsSection';
import PersonCard, { PersonCardProps } from 'components/CMS/PersonCardsSection/PersonCard';
import QuoteSection, { QuoteSectionProps } from 'components/CMS/QuoteSection';
import TestimonialCardsSection, { TestimonialCardsSectionProps } from 'components/CMS/TestimonialCardsSection';
import TextCardCarousel, { TextCardCarouselProps } from 'components/CMS/TextCardCarousel';
import TextImageSection, { TextImageSectionProps } from 'components/CMS/TextImageSection';
import TextVideoSection, { TextVideoSectionProps } from 'components/CMS/TextVideoSection';
import InteractiveOceanToggle, { InteractiveOceanToggleProps } from 'components/CMS/InteractiveOceanToggle';
import ProductsSection, { ProductsSectionProps } from 'components/CMS/ProductsSection';
import RichTextSection, { RichTextSectionProps } from 'components/CMS/RichTextSection';
import CMSButton, { CMSButtonProps } from 'components/CMS/CMSButton';
import Footer, { FooterProps } from 'components/Common/Footer';
import Navbar, { NavbarProps } from 'components/Common/Navbar';
import CMSContainer, { CMSContainerProps } from 'components/CMS/CMSContainer';

Builder.registerComponent(AccordionSection, {
  name: 'AccordionSection',
  inputs: AccordionSectionProps,
});
Builder.registerComponent(AnimatedHeading, {
  name: 'AnimatedHeading',
  inputs: AnimatedHeadingProps,
});
Builder.registerComponent(BigVideoSection, {
  name: 'BigVideoSection',
  inputs: BigVideoSectionProps,
});
Builder.registerComponent(HeroBanner, {
  name: 'HeroBanner',
  inputs: HeroBannerProps,
});
Builder.registerComponent(HeroTextSection, {
  name: 'HeroTextSection',
  inputs: HeroTextSectionProps,
});
Builder.registerComponent(HotspotSection, {
  name: 'HotspotSection',
  inputs: HotspotSectionProps,
});
Builder.registerComponent(HubspotForm, {
  name: 'HubspotForm',
  inputs: HubspotFormProps,
});
Builder.registerComponent(IconCardsSection, {
  name: 'IconCardsSection',
  inputs: IconCardsSectionProps,
});
Builder.registerComponent(ImageCarousel, {
  name: 'ImageCarousel',
  inputs: ImageCarouselProps,
});
Builder.registerComponent(ImageSeparatorSection, {
  name: 'ImageSeparatorSection',
  inputs: ImageSeparatorSectionProps,
});
Builder.registerComponent(LogoBanner, {
  name: 'LogoBanner',
  inputs: LogoBannerProps,
});
Builder.registerComponent(PersonCard, {
  name: 'PersonCard',
  inputs: PersonCardProps,
});
Builder.registerComponent(PersonCardsSection, {
  name: 'PersonCardsSection',
  inputs: PersonCardsSectionProps,
});
Builder.registerComponent(QuoteSection, {
  name: 'QuoteSection',
  inputs: QuoteSectionProps,
});
Builder.registerComponent(TestimonialCardsSection, {
  name: 'TestimonialCardsSection',
  inputs: TestimonialCardsSectionProps,
});
Builder.registerComponent(TextCardCarousel, {
  name: 'TextCardCarousel',
  inputs: TextCardCarouselProps,
});
Builder.registerComponent(TextImageSection, {
  name: 'TextImageSection',
  inputs: TextImageSectionProps,
});
Builder.registerComponent(InteractiveOceanToggle, {
  name: 'InteractiveOceanToggle',
  inputs: InteractiveOceanToggleProps,
});
Builder.registerComponent(TextVideoSection, {
  name: 'TextVideoSection',
  inputs: TextVideoSectionProps,
});
Builder.registerComponent(ProductsSection, {
  name: 'ProductsSection',
  inputs: ProductsSectionProps,
});
Builder.registerComponent(RichTextSection, {
  name: 'RichTextSection',
  inputs: RichTextSectionProps,
});
Builder.registerComponent(CMSButton, {
  name: 'Button',
  inputs: CMSButtonProps,
});
Builder.registerComponent(CMSContainer, {
  name: 'Container',
  inputs: CMSContainerProps,
});
Builder.registerComponent(Navbar, {
  name: 'Navbar',
  inputs: NavbarProps,
});
Builder.registerComponent(Footer, {
  name: 'Footer',
  inputs: FooterProps,
});

export interface ISeoComponentProps {
  title: string;
  description: string;
  keywords: string[];
  /* eslint-disable camelcase */
  twitterCard: string;
  twitterTitle: string;
  twitterDescription: string;
  twitterImage: string;
  twitterImageAlt: string;
  ogUrl: string;
  ogType: string;
  ogTitle: string;
  ogDescription: string;
  ogImage: string;
  ogImageAlt: string;
  /* eslint-enable camelcase */
}

/**
 * @param {ISeoComponentProps} seoComponentProps
 * @return {object}
 */
export function getSeoMetaTags(seoComponentProps: ISeoComponentProps) {
  const {
    title,
    description,
    keywords = [],
    /* eslint-disable camelcase */
    twitterCard,
    twitterTitle,
    twitterDescription,
    twitterImage,
    twitterImageAlt,
    ogUrl,
    ogType,
    ogTitle,
    ogDescription,
    ogImage,
    ogImageAlt,
    /* eslint-enable camelcase */
  } = seoComponentProps || {};

  return [
    {
      name: 'title',
      content: title,
    },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'keywords',
      content: keywords.join(', '),
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
    {
      name: 'revisit-after',
      content: '15 days',
    },
    {
      name: 'author',
      content: 'Genleap',
    },
    {
      name: 'twitter:card',
      content: twitterCard,
    },
    {
      name: 'twitter:site',
      content: 'www.genleap.co',
    },
    {
      name: 'twitter:title',
      content: twitterTitle || title,
    },
    {
      name: 'twitter:description',
      content: twitterDescription,
    },
    {
      name: 'twitter:image',
      content: twitterImage,
    },
    {
      property: 'twitter:image:alt',
      content: twitterImageAlt,
    },
    {
      name: 'twitter:creator',
      content: '@GenLeapOrg',
    },
    {
      property: 'og:url',
      content: ogUrl,
    },
    {
      property: 'og:type',
      content: ogType,
    },
    {
      property: 'og:title',
      content: ogTitle,
    },
    {
      property: 'og:description',
      content: ogDescription,
    },
    {
      property: 'og:image',
      content: ogImage,
    },
    {
      property: 'og:image:alt',
      content: ogImageAlt,
    },
  ];
}
