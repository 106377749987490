import { Button, GlobalStyles, styled, Typography } from '@mui/material';

export const StyledComponents = {
  Modal: styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
  }),
  ModalContent: styled('div')({
    backgroundColor: 'white',
    maxWidth: 560,
    width: '90%',
    padding: '25px 20px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  ModalIcon: styled('div')({
    width: 80,
    height: 80,
    margin: 'auto',
    backgroundColor: 'white',

    '& svg': {
      width: '100%',
      height: '100%',
    },
  }),
  ModalTitle: styled(Typography)({
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1,
    textAlign: 'center',
    color: '#2d2c5c',
    marginTop: 30,
    marginBottom: 20,
  }),
  ModalDescription: styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: 16,
    lineHeight: 1.38,
    textAlign: 'center',
  }),
  ModalConfirmButton: styled(Button)({
    borderRadius: '25px !important',
    textTransform: 'capitalize',
    padding: '5px 30px',
    margin: 'auto',
    marginTop: 30,
  }),
  ModalCancelButton: styled(Button)({
    height: 54,
    padding: 0,
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  }),

  ModalFooter: styled('p')({
    textAlign: 'center',
    marginTop: 20,
    fontSize: 15,
    color: '#000',

    '& span': {
      color: '#2d2c5c',
      textDecoration: 'underline',
    },
  }),
};

export const CssStyles = (
  <GlobalStyles
    styles={{
      ['.datetimePicker-435436']: {
        '&.PrivatePickersFadeTransitionGroup-root': {
          display: 'flex',
          flexDirection: 'column-reverse',
        },
      },
      ['.timeInput-43782']: {
        '&:hover': {
          outline: 'none',
          '-webkit-box-shadow': 'none',
          boxShadow: 'none',
        },
        '&:focus': {
          outline: 'none',
          '-webkit-box-shadow': 'none',
          boxShadow: 'none',
        },
        '&::-webkit-calendar-picker-indicator': {
          display: 'none',
        },
        '& label.Mui-focused': {
          color: '#c1c0e1',
        },
        '& .MuiInput-underline:after': {
          borderColor: '#c1c0e1',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#c1c0e1',
          },
          '&:hover fieldset': {
            borderColor: '#c1c0e1',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#c1c0e1',
          },
        },
      },
    }}
  />
);
