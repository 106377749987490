import { styled } from '@mui/material/styles';

export const StyledComponents = {
  Container: styled('div')(() => ({
    fontFamily: 'Montserrat',
    paddingLeft: '20px',
    paddingRight: '20px',
    '& p': {
      fontFamily: 'Open Sans',
    },
  })),
};
