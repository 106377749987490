import { styled } from '@mui/material/styles';
import { Button, ButtonProps, Card, CardProps, Typography, TypographyProps } from '@mui/material';

import { ThemeColorMap } from 'constants/themeColors';
import { CMSThemeColors } from 'lib/theme';

interface StyledHeadingProps extends TypographyProps {
  isCardVariantLarge?: boolean;
}

interface StyledButtonProps extends ButtonProps {
  isCardVariantLarge?: boolean;
}

interface StyledIconCardProps extends CardProps {
  backgroundColor?: CMSThemeColors;
}

export const StyledComponents = {
  IconCard: styled(Card, {
    shouldForwardProp: (prop) => !['backgroundColor'].includes(prop as string),
  })<StyledIconCardProps>(({ backgroundColor }) => ({
    backgroundColor: backgroundColor ? ThemeColorMap.get(backgroundColor) : 'transparent',
    boxShadow: 'none',
    width: '100%',
    border: 'none',
    paddingBottom: 16,
    paddingTop: 16,
    height: '100%',
    paddingRight: 0,
    paddingLeft: 0,
  })),
  ImageContainer: styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    borderRadius: '50%',
    overflow: 'hidden',
    width: 236,
    height: 236,
    margin: 'auto',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      width: 180,
      height: 180,
    },
    [theme.breakpoints.down('md')]: {
      width: 160,
      height: 160,
    },

    '& img': {
      borderRadius: '50%',
    },
  })),
  DefaultImage: styled('div')(({ theme }) => ({
    background: 'white',
    height: 236,
    width: 236,
    borderRadius: '50%',
    [theme.breakpoints.down('xl')]: {
      width: 180,
      height: 180,
    },
    [theme.breakpoints.down('md')]: {
      width: 160,
      height: 160,
    },
  })),
  HeadingLight: styled(Typography, {
    shouldForwardProp: (prop) => !['isCardVariantLarge'].includes(prop as string),
  })<StyledHeadingProps>(({ theme, isCardVariantLarge }) => ({
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: isCardVariantLarge ? 18 : undefined, //  This value is undefined because heading light is not being show in small variant
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  })),
  HeadingBold: styled('div', {
    shouldForwardProp: (prop) => !['isCardVariantLarge'].includes(prop as string),
  })<StyledHeadingProps>(({ theme, isCardVariantLarge }) => ({
    fontSize: isCardVariantLarge ? 24 : 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  })),
  Description: styled(Typography)<TypographyProps>(({ theme }) => ({
    marginTop: 20,
    fontSize: 16,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  })),
  Button: styled(Button, {
    shouldForwardProp: (prop) => !['isCardVariantLarge'].includes(prop as string),
  })<StyledButtonProps>(({ theme, isCardVariantLarge }) => ({
    margin: 'auto',
    borderRadius: 10,
    marginTop: 16,
    height: isCardVariantLarge ? 60 : 50,
    padding: isCardVariantLarge ? '20px 40px' : '20px 35px',
    fontSize: 16,
    [theme.breakpoints.down('xl')]: {
      height: isCardVariantLarge ? 50 : 40,
      padding: isCardVariantLarge ? '16px 26px' : '20px 30px',
    },
    [theme.breakpoints.down('md')]: {
      height: 40,
      padding: '10px 20px',
      fontSize: 14,
    },
    '&:hover': {
      color: 'white',
    },
  })),
};
