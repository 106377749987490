import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';

import ProductCard, { IProduct } from 'components/Product/ProductCard';
import Spacer from 'components/Common/Spacer';
import { StyledComponents } from './styled';

export interface IProductListProps {
  products: IProduct[];
}

/**
 * Renders Product List
 * @param {IProductListProps} props
 * @return {ReactElement}
 */
function ProductList(props: IProductListProps): ReactElement {
  const { products } = props;

  return (
    <div>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: 'center',
        }}
      >
        {products.map((product, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
          >
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
      <Spacer space="30px" />
      <StyledComponents.TaxText variant="body2">*Prices are exclusive of GST.</StyledComponents.TaxText>
    </div>
  );
}

export default ProductList;
