import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';

interface StyledRootProps {
  sectionBackgroundImage: string;
  sectionBackgroundImageMobile: string;
}

export const StyledComponents = {
  Root: styled('div')<StyledRootProps>(({ theme, sectionBackgroundImage, sectionBackgroundImageMobile }) => ({
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    backgroundImage: `url(${sectionBackgroundImage})`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      backgroundImage: `url(${sectionBackgroundImageMobile})`,
    },
  })),
  Container: styled('div')(({ theme }) => ({
    width: '43%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: 'linear-gradient(323deg, rgba(119, 4, 157, 0.3) , #dd004d);',
    [theme.breakpoints.down('md')]: {
      backgroundImage: 'linear-gradient(180deg, rgba(119, 4, 157, 0.3) , #dd004d);',
      height: '50%',
      width: '100%',
    },
  })),
  TextContainer: styled(Typography)<TypographyProps>(({ theme }) => ({
    width: '90%',
    maxHeight: '70%',
    overflow: 'auto',
    whiteSpace: 'pre-line',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      maxHeight: '80%',
      maxWidth: '80%',
      fontSize: '28px',
    },
  })),
};
