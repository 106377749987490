import { ReactElement } from 'react';
import { Input } from '@builder.io/sdk/dist';

import { ThemeColorMap } from 'constants/themeColors';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface IImageSeparatorSectionProps {
  heading: string;
  headingColor?: CMSThemeColors;
  alignment?: 'left' | 'center' | 'right';
  sectionBackgroundImage: string;
  sectionBackgroundImageMobile: string;
}

export const ImageSeparatorSectionProps: Input[] = [
  { name: 'heading', type: 'string', required: true },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'alignment', type: 'enum', enum: ['left', 'center', 'right'] },
  { name: 'sectionBackgroundImage', type: 'file', allowedFileTypes: CMSAllowedImageTypes, required: true },
  { name: 'sectionBackgroundImageMobile', type: 'file', allowedFileTypes: CMSAllowedImageTypes, required: true },
];

/**
 * Renders ImageSeparatorSection
 *
 * @param {IImageSeparatorSectionProps} props
 * @return {ReactElement}
 */
export default function ImageSeparatorSection({
  heading,
  headingColor = 'white',
  alignment = 'left',
  sectionBackgroundImage,
  sectionBackgroundImageMobile,
}: IImageSeparatorSectionProps): ReactElement {
  return (
    <StyledComponents.Root
      sectionBackgroundImage={sectionBackgroundImage}
      sectionBackgroundImageMobile={sectionBackgroundImageMobile}
    >
      <StyledComponents.Container>
        <StyledComponents.TextContainer
          variant="h2"
          style={{
            textAlign: alignment,
            color: ThemeColorMap.get(headingColor),
          }}
        >
          {heading}
        </StyledComponents.TextContainer>
      </StyledComponents.Container>
    </StyledComponents.Root>
  );
}
