import { ReactElement } from 'react';
import Link from 'next/link';
import { useMediaQuery } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';

import { ThemeColorMap } from 'constants/themeColors';
import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import ResponsiveVideo from 'components/Common/ResponsiveVideo';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { StyledComponents } from './styled';

export interface IBigVideoSectionProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  alignment?: 'right' | 'left' | 'center';
  videoAlignment?: 'top' | 'bottom';
  videoUrl: string;
  primaryButtonText?: string;
  primaryButtonLink?: string;
  sectionBackgroundColor?: CMSThemeColors;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
}

export const BigVideoSectionProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'enum', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'enum', enum: CMSThemeColorsList },
  { name: 'alignment', type: 'enum', enum: ['right', 'left', 'center'] },
  { name: 'videoAlignment', type: 'enum', enum: ['top', 'bottom'] },
  { name: 'videoUrl', type: 'string' },
  { name: 'primaryButtonText', type: 'string' },
  { name: 'primaryButtonLink', type: 'string' },
  { name: 'sectionBackgroundColor', type: 'enum', enum: CMSThemeColorsList },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
];

/**
 * Renders Big Video Section
 *
 * @param {IBigVideoSectionProps} props
 * @return {ReactElement}
 */
export default function BigVideoSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  alignment = 'center',
  videoAlignment = 'bottom',
  videoUrl,
  primaryButtonText,
  primaryButtonLink,
  sectionBackgroundColor = 'white',
  sectionPaddingTop,
  sectionPaddingBottom,
}: IBigVideoSectionProps): ReactElement {
  const direction = videoAlignment === 'bottom' ? 'column' : 'column-reverse';
  const matches = useMediaQuery('(max-width:600px)');

  if (matches) {
    alignment = 'center';
  }

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      <StyledComponents.Container style={{ flexDirection: direction }}>
        <StyledComponents.TextContainer>
          {heading && (
            <StyledComponents.Heading
              style={{
                color: ThemeColorMap.get(headingColor),
                textAlign: alignment,
                margin: videoAlignment === 'top' ? '20px 0px 0px 0px' : '0px 0px 20px 0px',
              }}
              variant="h2"
            >
              {heading}
            </StyledComponents.Heading>
          )}
          {description?.trim() && (
            <StyledComponents.Description
              variant="body1"
              style={{
                color: ThemeColorMap.get(descriptionColor),
                textAlign: alignment,
                margin: videoAlignment === 'top' ? '20px 0px 0px 0px' : '0px 0px 20px 0px',
              }}
            >
              {description?.trim()}
            </StyledComponents.Description>
          )}
          {primaryButtonText && primaryButtonLink && (
            <div style={{ textAlign: alignment }}>
              <Link href={primaryButtonLink}>
                <StyledComponents.Button
                  style={{
                    margin: videoAlignment === 'top' ? '20px 0px 0px 0px' : '0px 0px 20px 0px',
                  }}
                  variant="contained"
                  color="primary"
                >
                  {primaryButtonText}
                </StyledComponents.Button>
              </Link>
            </div>
          )}
        </StyledComponents.TextContainer>
        <StyledComponents.VideoContainer>
          <ResponsiveVideo url={videoUrl} />
        </StyledComponents.VideoContainer>
      </StyledComponents.Container>
    </SectionWrapper>
  );
}
