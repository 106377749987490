import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import SectionHeader from 'components/CMS/_Common/SectionHeader';
import SectionDescription from 'components/CMS/_Common/SectionDescription';
import IconCard, { IIconCardProps } from './IconCard';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';

export interface IIconCardsSectionProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundGradient?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  cardVariant?: string;
  hiddenBorder?: boolean;
  iconCards: IIconCardProps[];
}

export const IconCardsSectionProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  { name: 'cardVariant', type: 'string' },
  { name: 'hiddenBorder', type: 'boolean' },
  {
    name: 'iconCards',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'headingLight', type: 'string' },
      { name: 'headingLightColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'headingBold', type: 'string' },
      { name: 'headingBoldColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'description', type: 'string' },
      { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'image', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
      { name: 'alignment', type: 'enum', enum: ['left', 'center', 'right'] },
      { name: 'primaryButtonText', type: 'string' },
      { name: 'primaryButtonLink', type: 'string' },
      { name: 'backgroundColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'isCardVariantLarge', type: 'boolean' },
    ],
  },
];

/**
 * Renders Icon Cards Section
 *
 * @param {IIconCardsSectionProps} props
 * @return {ReactElement}
 */
export default function IconCardsSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor,
  sectionBackgroundGradient,
  sectionPaddingTop,
  sectionPaddingBottom,
  cardVariant = 'Large',
  iconCards,
  hiddenBorder,
}: IIconCardsSectionProps): ReactElement {
  const isCardVariantLarge = cardVariant.toLowerCase() === 'large' ? true : false;

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgGradient={sectionBackgroundGradient}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      {heading && (
        <SectionHeader
          heading={heading}
          headingColor={headingColor}
        />
      )}
      {description && (
        <SectionDescription
          description={description}
          descriptionColor={descriptionColor}
        />
      )}
      <Box
        sx={{
          display: 'grid',
          gridGap: '1rem',
          gridAutoRows: '1fr',
          gridTemplateColumns: {
            md: 'repeat(3,1fr)',
            sm: 'repeat(2,1fr)',
            xs: '1fr',
          },
          marginTop: '20px',
        }}
      >
        {iconCards.map((ele, i) => {
          return (
            <Box
              key={i}
              sx={{
                border: hiddenBorder ? 'none' : 'solid 1px #f5f2f2',
                borderRadius: '12px',
              }}
            >
              <IconCard
                {...ele}
                isCardVariantLarge={isCardVariantLarge}
              />
            </Box>
          );
        })}
      </Box>
    </SectionWrapper>
  );
}
