export enum PROFILE_TYPE_TO_TEXT {
  MY_CHILD = 'Dependent Child ',
  SELF = 'Self (18+)',
}

export const PRODUCT_IMAGE_PLACEHOLDER =
  'https://cdn.zeplin.io/6107a8eda3daf511b7b2ca9e/assets/08FFE2D7-5387-4CD0-A6C0-514367972C53.png';

export const SELECTED_PRODUCT_KEY = 'gl_sp';
export const RECONDITIONED_AGE_GROUP_KEY = 'gl_ag';
export const PROFILE_TYPE_KEY = 'gl_pt';
