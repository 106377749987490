import React, { ReactElement } from 'react';

export interface ISEOProps {
  metaTags?: IMetaProps[];
}

export interface IMetaProps {
  name?: string;
  property?: string;
  content?: string;
}

const Meta = ({ name, property, content }: IMetaProps): ReactElement => {
  if (!content) return <></>;
  return (
    <meta
      name={name}
      property={property}
      content={content}
    />
  );
};

/**
 * Renders SEO Meta Tags
 *
 * @param {ISEOProps} props
 * @return {ReactElement}
 */
export default function SEO({ metaTags = [] }: ISEOProps): ReactElement {
  return (
    <>
      {metaTags.map((meta, index) => {
        return (
          <Meta
            {...meta}
            key={index}
          />
        );
      })}
    </>
  );
}
