import React, { ReactElement } from 'react';

/**
 *
 * @return {ReactElement}
 */
export function LinkedInIcon({ color = 'white' }: { color?: string }): ReactElement {
  return (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 450 450"
        xmlSpace="preserve"
      >
        <path
          style={{ fill: color, border: '2px solid black' }}
          d="M225,31C117.86,31,31,117.86,31,225v0c0,107.14,86.86,194,194,194c107.14,0,194-86.86,194-194v0
	C419,117.86,332.14,31,225,31z M169.23,326h-46.15V176.91h46.15V326z M145.93,157.38c-15.08,0-27.29-12.33-27.29-27.52
	c0-15.19,12.21-27.51,27.29-27.51s27.29,12.32,27.29,27.51C173.22,145.06,161,157.38,145.93,157.38z M342.18,326h-45.93
	c0,0,0-56.79,0-78.26c0-21.46-8.15-33.45-25.12-33.45c-18.47,0-28.12,12.49-28.12,33.45c0,22.97,0,78.26,0,78.26h-44.27V176.91H243
	v20.08c0,0,13.31-24.63,44.93-24.63c31.61,0,54.25,19.31,54.25,59.25C342.18,271.54,342.18,326,342.18,326z"
        />
      </svg>
    </div>
  );
}
