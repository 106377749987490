import { styled } from '@mui/material/styles';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

import { ThemeColorMap } from 'constants/themeColors';

const getColor = (color: string) => {
  const themeColorHex = ThemeColorMap.get(color);

  if (themeColorHex) return themeColorHex;

  return color;
};

interface StyledButtonBaseProps extends ButtonBaseProps {
  active: boolean;
  color: string;
  toggleDisabled: boolean;
  showDisabled: boolean;
}

export const StyledComponents = {
  ToggleButton: styled(ButtonBase, {
    shouldForwardProp: (prop) => !['active', 'toggleDisabled', 'color', 'showDisabled'].includes(prop as string),
  })<StyledButtonBaseProps>(({ theme, active, toggleDisabled, color, showDisabled }) => ({
    '&.MuiButtonBase-root': {
      padding: '15px 50px',
      borderRadius: '50px !important',
      flex: 1,
      fontSize: '0.9rem',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      cursor: toggleDisabled ? 'text' : 'pointer',
      opacity: !active && (showDisabled || toggleDisabled) ? 0.4 : 1,
      color: active ? 'white' : getColor(color),
      backgroundColor: active ? getColor(color) : 'white',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 25px',
        fontSize: '0.8rem',
      },
    },
  })),
};
