import { styled } from '@mui/material/styles';
import { CardMedia, Typography, CardMediaProps, TypographyProps } from '@mui/material';

interface StyledCardMediaProps extends CardMediaProps {
  component: string;
  alt: string;
}

export const StyledComponents = {
  ImageContainer: styled(CardMedia)<StyledCardMediaProps>(({ theme }) => ({
    width: '100%',
    height: 260,
    objectPosition: 'top',
    border: 'none',
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  })),
  Description: styled(Typography)<TypographyProps>(({ theme }) => ({
    textAlign: 'left',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      height: '90px',
      overflow: 'auto',
      textAlign: 'center',
    },
  })),
  Designation: styled(Typography)<TypographyProps>(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  })),
  Name: styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.pink.main,
    marginTop: 10,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  })),
  IconContainer: styled('div')({
    position: 'absolute',
    width: '100%',
    height: '50px',
    display: 'flex',
    padding: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '0px',
  }),
  Icon: styled('div')({
    '&:hover': {
      cursor: 'pointer',
    },
    height: '32px',
    width: '32px',
  }),
};
