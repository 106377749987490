import React, { ReactElement, useState } from 'react';

import ToggleButton, { IToggleButton } from './ToggleButton';
import { StyledComponents } from './styled';

export interface ICapsuleToggleProps {
  color?: string;
  toggleDisabled?: boolean;
  toggleButtons: IToggleButton[];
  defaultActiveButton?: IToggleButton;
  className?: string;
  onToggle: (value: string) => void;
}

/**
 * Renders Capsule Toggle
 *
 * @param {ICapsuleToggleProps} props
 * @return {ReactElement}
 */
export default function CapsuleToggle({
  color = 'blue',
  toggleDisabled = false,
  toggleButtons,
  defaultActiveButton = toggleButtons[0],
  className,
  onToggle,
}: ICapsuleToggleProps): ReactElement {
  const [activeButtonKey, setActiveButtonKey] = useState<string>(
    defaultActiveButton.value || defaultActiveButton.label,
  );

  const handleClick = (value: string) => {
    if (toggleDisabled) return;
    setActiveButtonKey(value);
    onToggle(value);
  };

  const isToggleButtonActive = (toggleButton: IToggleButton) => {
    const value = toggleButton.value || toggleButton.label;

    if (activeButtonKey === value) return true;

    return false;
  };

  return (
    <StyledComponents.Container
      color={color}
      className={className}
    >
      {toggleButtons.map((toggleButton, index) => {
        return (
          <ToggleButton
            key={`toggle-button-${index}`}
            label={toggleButton.label}
            value={toggleButton.value}
            color={color}
            toggleDisabled={toggleDisabled}
            showDisabled={!isToggleButtonActive(toggleButton)}
            onClick={handleClick}
            active={isToggleButtonActive(toggleButton)}
          />
        );
      })}
    </StyledComponents.Container>
  );
}
