import { styled } from '@mui/material/styles';
import { Button, ButtonProps, Container, ContainerProps, Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  Root: styled('div')({
    position: 'relative',
    minHeight: 'max(65vh, 650px)',
  }),
  BgImage: styled('div')({
    minHeight: 'max(65vh, 650px)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
  MainContentWrapper: styled(Container)<ContainerProps>({
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  MainContent: styled('div')({
    zIndex: 10,
  }),
  Title: styled(Typography)<TypographyProps>({
    maxWidth: 920,
    marginBottom: 20,
    zIndex: 10,
  }),
  Description: styled(Typography)<TypographyProps>({
    maxWidth: 920,
    marginBottom: 30,
    zIndex: 10,
    lineHeight: 1.4,
    whiteSpace: 'pre-line',
  }),
  Button: styled(Button)<ButtonProps>(({ theme }) => ({
    minWidth: 180,
    borderRadius: 12,
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(2),
  })),
};
