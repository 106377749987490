import { ReactElement } from 'react';
import Link from 'next/link';
import { Input } from '@builder.io/sdk/dist';

import { ThemeColorMap } from 'constants/themeColors';
import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { StyledComponents } from './styled';

export interface IHeroTextSectionProps {
  heading: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  alignment?: 'right' | 'center' | 'left';
  sectionBackgroundColor?: CMSThemeColors;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  primaryButtonText?: string;
  primaryButtonLink?: string;
}

enum AlignmentMap {
  'center' = 'center',
  'right' = 'end',
  'left' = 'start',
}

export const HeroTextSectionProps: Input[] = [
  { name: 'heading', type: 'string', required: true },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'enum', enum: CMSThemeColorsList },
  { name: 'alignment', type: 'string', enum: ['right', 'center', 'left'] },
  { name: 'sectionBackgroundColor', type: 'enum', enum: CMSThemeColorsList },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  { name: 'primaryButtonText', type: 'string' },
  { name: 'primaryButtonLink', type: 'string' },
];

/**
 * Renders Hero Text Section
 *
 * @param {IHeroTextSectionProps} props
 * @return {ReactElement}
 */
export default function HeroTextSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  alignment = 'center',
  sectionBackgroundColor,
  sectionPaddingTop,
  sectionPaddingBottom,
  primaryButtonText,
  primaryButtonLink,
}: IHeroTextSectionProps): ReactElement {
  const textAlignment = alignment;

  return (
    <SectionWrapper
      className={StyledComponents.Root.toString()}
      bgColor={sectionBackgroundColor}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      <StyledComponents.Container
        maxWidth="lg"
        sx={{
          justifyContent: AlignmentMap[alignment],
        }}
      >
        <StyledComponents.TextWrapper>
          <StyledComponents.Title
            variant="h2"
            sx={{
              color: ThemeColorMap.get(headingColor),
              textAlign: textAlignment,
            }}
          >
            {heading}
          </StyledComponents.Title>
          {description && (
            <StyledComponents.Description
              variant="body1"
              sx={{
                color: ThemeColorMap.get(descriptionColor),
                textAlign: textAlignment,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></StyledComponents.Description>
          )}
        </StyledComponents.TextWrapper>
        {primaryButtonText && primaryButtonLink && (
          <Link href={primaryButtonLink ?? ''}>
            <StyledComponents.Button
              variant="contained"
              color="primary"
            >
              {primaryButtonText}
            </StyledComponents.Button>
          </Link>
        )}
      </StyledComponents.Container>
    </SectionWrapper>
  );
}
