import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledComponents = {
  ToggleDescription: styled(Typography)<{
    isActive: boolean;
    hidden: boolean;
    placement: 'left' | 'right';
  }>(({ isActive, hidden, placement }) => ({
    color: isActive ? 'inherit' : '#80808090',
    transition: 'color ease 0.4s, scale ease 0.4s',
    display: 'flex',
    textAlign: placement === 'right' ? undefined : 'end',
    width: '50%',
    ...(hidden
      ? {
          contentVisibility: 'hidden',
        }
      : {}),
  })),
};
