import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  Header: styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: 40,
    textAlign: 'center',
    margin: 'auto',
    width: '100%',
    lineHeight: 1.3,
    letterSpacing: 0.64,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
      width: '94%',
      marginBottom: 10,
    },
  })),
};
