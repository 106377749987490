import { ReactElement } from 'react';
import { Input } from '@builder.io/sdk/dist';
import Slider from 'react-slick';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { CMSAllowedImageTypes } from 'constants/common';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { StyledComponents } from './styled';

interface IImage {
  url: string;
  altText: string;
}

export interface IImageCarouselProps {
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundGradient?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  fullWidth?: boolean;
  images: IImage[];
}

export const ImageCarouselProps: Input[] = [
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  { name: 'fullWidth', type: 'boolean' },
  {
    name: 'images',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'altText', type: 'string' },
      { name: 'url', type: 'file', required: true, allowedFileTypes: CMSAllowedImageTypes },
    ],
  },
];

/**
 * @param {IImageCarouselProps} props
 * @return {ReactElement}
 */
export default function ImageCarousel({
  sectionBackgroundColor = 'white',
  sectionBackgroundGradient,
  sectionPaddingTop,
  sectionPaddingBottom,
  fullWidth = true,
  images,
}: IImageCarouselProps): ReactElement {
  const slickSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    dots: fullWidth ? false : true,
    pauseOnHover: false,
  };

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgGradient={sectionBackgroundGradient}
      paddingTop={fullWidth ? '0px' : sectionPaddingTop}
      paddingBottom={fullWidth ? '0px' : sectionPaddingBottom}
      fullWidth={fullWidth}
    >
      <StyledComponents.CarouselWrapper className={fullWidth ? 'full-width-slider' : ''}>
        <Slider {...slickSettings}>
          {images.map((img, idx) => (
            <StyledComponents.ImageWrapper key={idx}>
              <img
                src={img.url}
                alt={img.altText}
              />
            </StyledComponents.ImageWrapper>
          ))}
        </Slider>
      </StyledComponents.CarouselWrapper>
    </SectionWrapper>
  );
}
