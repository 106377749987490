import { ReactElement, useState } from 'react';
import { Input } from '@builder.io/sdk/dist';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { ThemeColorMap } from 'constants/themeColors';
import { CMSAllowedImageTypes } from 'constants/common';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { StyledComponents } from './styled';

export interface IQuoteSectionProps {
  quote: string;
  authorName?: string;
  authorDesignation?: string;
  icon?: string;
  textColor?: CMSThemeColors;
  quoteContainerColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundGradient?: string;
  sectionBackgroundImage?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
}

export const QuoteSectionProps: Input[] = [
  { name: 'quote', type: 'string', required: true },
  { name: 'authorName', type: 'string' },
  { name: 'authorDesignation', type: 'string' },
  { name: 'icon', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
  { name: 'textColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'quoteContainerColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionBackgroundImage', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
];

/**
 * Renders QuoteSection
 *
 * @param {IQuoteSectionProps} props
 * @return {ReactElement}
 */
export default function QuoteSection({
  quote,
  authorName,
  authorDesignation,
  icon,
  quoteContainerColor = 'pink',
  sectionBackgroundColor = 'white',
  textColor = 'navy',
  sectionBackgroundGradient,
  sectionBackgroundImage,
  sectionPaddingTop,
  sectionPaddingBottom,
}: IQuoteSectionProps): ReactElement {
  const [iconError, setIconError] = useState(false);

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgGradient={sectionBackgroundGradient}
      bgImage={sectionBackgroundImage}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      <StyledComponents.QuoteContainer
        style={{
          background: ThemeColorMap.get(quoteContainerColor),
          color: ThemeColorMap.get(textColor),
        }}
      >
        <StyledComponents.Icon>
          {!icon || iconError ? (
            <span style={{ fontSize: '11rem', marginTop: '6rem' }}>&#8221;</span>
          ) : (
            <img
              src={icon}
              width="80%"
              alt="icon image"
              onError={() => {
                setIconError(true);
              }}
            />
          )}
        </StyledComponents.Icon>
        <StyledComponents.QuoteText
          sx={{
            color: ThemeColorMap.get(textColor),
          }}
          variant="h3"
        >
          {quote}
        </StyledComponents.QuoteText>
        <StyledComponents.QuoteAuthor
          sx={{
            color: ThemeColorMap.get(textColor),
          }}
          variant="body1"
        >
          {authorName ? `- ${authorName}` : ''} {authorDesignation ? `(${authorDesignation})` : ''}
        </StyledComponents.QuoteAuthor>
      </StyledComponents.QuoteContainer>
    </SectionWrapper>
  );
}
