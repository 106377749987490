import { ReactElement, useRef, LegacyRef } from 'react';
import Link from 'next/link';
import Slider, { Settings } from 'react-slick';
import { useMediaQuery, useTheme } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';

import { ThemeColorMap } from 'constants/themeColors';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

interface IBackground {
  desktop: string;
  mobile: string;
}

export interface IHeroBannerProps {
  heading: string;
  headingColor?: CMSThemeColors;
  description: string;
  descriptionColor?: CMSThemeColors;
  alignment: 'left' | 'right' | 'center';
  primaryButtonText?: string;
  primaryButtonLink?: string;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  backgrounds: IBackground[];
}

const slickSettings: Settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  pauseOnHover: false,
  autoplay: false,
};

export const HeroBannerProps: Input[] = [
  { name: 'heading', type: 'string', required: true },
  { name: 'headingColor', type: 'enum', enum: CMSThemeColorsList },
  { name: 'description', type: 'string', required: true },
  { name: 'descriptionColor', type: 'enum', enum: CMSThemeColorsList },
  { name: 'alignment', required: true, type: 'enum', enum: ['left', 'right', 'center'] },
  { name: 'primaryButtonText', type: 'string' },
  { name: 'primaryButtonLink', type: 'string' },
  { name: 'secondaryButtonText', type: 'string' },
  { name: 'secondaryButtonLink', type: 'string' },
  {
    name: 'backgrounds',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      {
        name: 'mobile',
        type: 'file',
        allowedFileTypes: CMSAllowedImageTypes,
        required: true,
      },
      {
        name: 'desktop',
        type: 'file',
        allowedFileTypes: CMSAllowedImageTypes,
        required: true,
      },
    ],
  },
];

/**
 * Renders Hero Banner
 *
 * @param {IHeroBannerProps} props
 * @return {ReactElement}
 */
export default function HeroBanner({
  heading,
  headingColor = 'white',
  description,
  descriptionColor = 'white',
  alignment = 'left',
  primaryButtonText,
  primaryButtonLink,
  secondaryButtonText,
  secondaryButtonLink,
  backgrounds,
}: IHeroBannerProps): ReactElement {
  const theme = useTheme();
  const sliderRef = useRef<Slider>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledComponents.Root>
      <Slider
        ref={sliderRef as LegacyRef<Slider>}
        {...slickSettings}
      >
        {backgrounds.map((img, idx) => {
          const accessKey = isMobile ? 'mobile' : 'desktop';
          const bgImage = img[accessKey] ? `url(${img[accessKey]})` : undefined;
          return (
            <div key={idx}>
              <StyledComponents.BgImage
                style={{
                  backgroundImage: bgImage,
                }}
              />
            </div>
          );
        })}
      </Slider>
      <StyledComponents.MainContentWrapper maxWidth="lg">
        <StyledComponents.MainContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledComponents.Title
              variant="h1"
              style={{
                color: ThemeColorMap.get(headingColor),
                fontSize: isMobile ? '2rem' : undefined,
                alignSelf: alignment,
              }}
            >
              {heading}
            </StyledComponents.Title>
            {description && (
              <StyledComponents.Description
                variant="body1"
                style={{
                  color: ThemeColorMap.get(descriptionColor),
                  fontSize: isMobile ? '1rem' : '1.125rem',
                  alignSelf: alignment,
                }}
              >
                {description}
              </StyledComponents.Description>
            )}
            <div
              style={{
                textAlign: alignment,
              }}
            >
              <div
                style={{
                  marginBottom: 16,
                  marginRight: 10,
                  display: 'inline-block',
                }}
              >
                {primaryButtonText && primaryButtonLink && (
                  <Link href={primaryButtonLink}>
                    <StyledComponents.Button
                      color="primary"
                      variant="contained"
                    >
                      {primaryButtonText}
                    </StyledComponents.Button>
                  </Link>
                )}
                {secondaryButtonText && secondaryButtonLink && (
                  <Link href={secondaryButtonLink}>
                    <StyledComponents.Button
                      color="secondary"
                      variant="outlined"
                    >
                      {secondaryButtonText}
                    </StyledComponents.Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </StyledComponents.MainContent>
      </StyledComponents.MainContentWrapper>
    </StyledComponents.Root>
  );
}
