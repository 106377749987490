import { MarkEmailUnread } from '@mui/icons-material';
import { Modal } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { StyledComponents } from './styled';

export const VerifyEmailModal = () => {
  const router = useRouter();
  const [openVerifyEmailModal, setOpenVerifyEmailModal] = useState(false);

  useEffect(() => {
    const error = router.query.error;
    const description = router.query.error_description;

    if (error && description?.includes('email')) {
      setOpenVerifyEmailModal(true);
    }
  }, [router.query]);

  const handleCloseModal = () => {
    setOpenVerifyEmailModal(false);
  };

  return (
    <div>
      <Modal
        open={openVerifyEmailModal}
        onClose={handleCloseModal}
      >
        <StyledComponents.Modal>
          <StyledComponents.ModalContent>
            <StyledComponents.ModalIcon>
              <MarkEmailUnread />
            </StyledComponents.ModalIcon>
            <StyledComponents.ModalTitle>Verify your email</StyledComponents.ModalTitle>
            <StyledComponents.ModalDescription>
              Almost done! We have sent you a verification email. Please verify your email address to continue.
            </StyledComponents.ModalDescription>
            <StyledComponents.ModalConfirmButton
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleCloseModal}
            >
              Ok
            </StyledComponents.ModalConfirmButton>
          </StyledComponents.ModalContent>
        </StyledComponents.Modal>
      </Modal>
    </div>
  );
};
