import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  Inline: styled('div')({
    display: 'inline',
  }),
  Heading: styled(Typography)<TypographyProps>(({ theme }) => ({
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
    },
  })),
};
