import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography, Box } from '@mui/material';

import { ProductAgeGroup } from 'constants/common';
import { ProfileType } from 'types';

interface Props {
  openModal: boolean;
  ageGroup: ProductAgeGroup;
  setOpenModal: (openModal: boolean) => void;
  handleContinue: (profileType: ProfileType) => void;
}

interface IButtonData {
  text: string;
  buttonVariant: 'text' | 'contained' | 'outlined' | undefined;
  disabled?: boolean;
  profileType: ProfileType;
}

export const BuyingForModal = ({ openModal, ageGroup, setOpenModal, handleContinue }: Props) => {
  const [profileType, setProfileType] = useState<ProfileType | null>(null);

  const buyingForStyles = (disabled?: boolean) => ({
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginRight: 12,
    // backgroundColor: disabled ? '#e5950b' : undefined,
    color: disabled ? '#fff' : undefined,
  });

  const getBuyingForButtons = () => {
    let buttonsData: IButtonData[] = [];

    if (ageGroup === ProductAgeGroup.GREATER_THAN_FIFTEEN) {
      buttonsData = [
        {
          text: 'Self',
          buttonVariant: profileType === ProfileType.Self ? 'contained' : 'outlined',
          profileType: ProfileType.Self,
        },
        {
          text: 'Dependent Child',
          buttonVariant: profileType === ProfileType.My_Child ? 'contained' : 'outlined',
          profileType: ProfileType.My_Child,
        },
      ];
    }

    return {
      buttonsData,
      components: buttonsData.map((data: IButtonData, index: number) => {
        return (
          <Button
            key={index}
            variant={data.buttonVariant}
            color="secondary"
            style={buyingForStyles(data.disabled) as React.CSSProperties}
            disabled={data.disabled}
            onClick={() => {
              if (data.disabled) {
                return;
              }
              setProfileType(data.profileType);
            }}
          >
            {data.text}
          </Button>
        );
      }),
    };
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setProfileType(null);
  };

  const { components: buyingForButtonsJSX } = getBuyingForButtons();
  const buyingForButtonsLength = buyingForButtonsJSX.length;

  return (
    <Dialog
      open={openModal}
      onClose={handleModalClose}
      PaperProps={{
        style: { maxWidth: '500px', borderRadius: 10 },
      }}
      fullWidth
    >
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          p={2}
          variant="h5"
          color={'navy'}
        >
          {buyingForButtonsLength > 1 ? 'Whom are you ordering for?' : 'I am ordering for'}
        </Typography>
        <div style={{ margin: 'auto 16px' }}>
          <IconButton
            onClick={handleModalClose}
            style={{
              border: '2px solid #dd004d',
              color: '#dd004d',
              padding: 0,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </Box>
      <Divider />
      <DialogContent style={{ padding: 18 }}>
        {buyingForButtonsLength > 1 && (
          <Typography
            variant="body2"
            color="navy"
            mb={1}
          >
            Choose
          </Typography>
        )}
        <Box
          display="flex"
          alignContent="center"
        >
          {buyingForButtonsJSX}
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: 18 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth={true}
          disableElevation
          onClick={() => handleContinue(profileType!)}
          disabled={!profileType}
        >
          CONTINUE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
