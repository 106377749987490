import { styled } from '@mui/material/styles';
import { Container, ContainerProps } from '@mui/material';

interface StyledContainerProps extends ContainerProps {
  fullWidth?: boolean;
}

export const StyledComponents = {
  Root: styled('section')(() => ({
    paddingLeft: 0,
    paddingRight: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  })),
  Container: styled(Container, {
    shouldForwardProp: (prop) => !['fullWidth'].includes(prop as string),
  })<StyledContainerProps>(({ theme, fullWidth }) => ({
    width: '100%',
    padding: 0,
    maxWidth: fullWidth ? '100% !important' : theme.breakpoints.values.lg,
    [theme.breakpoints.down('xl')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '94%',
    },
  })),
};
