import { ReactElement } from 'react';

import { ThemeColorMap } from 'constants/themeColors';
import { StyledComponents } from './styled';

interface ISectionDescriptionProps {
  description: string;
  descriptionColor?: string;
}

/**
 * Renders Section Description
 * @param {ISectionDescriptionProps} props
 * @return {ReactElement}
 */
export default function SectionDescription({ description, descriptionColor }: ISectionDescriptionProps): ReactElement {
  return (
    <StyledComponents.Description
      sx={{
        color: ThemeColorMap.get(descriptionColor || 'navy'),
      }}
    >
      {description}
    </StyledComponents.Description>
  );
}
