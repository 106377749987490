import { ReactElement, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Input } from '@builder.io/sdk/dist';
import Typist from 'react-typist';

import { ThemeColorMap } from 'constants/themeColors';
import { StyledComponents } from './styled';

export interface IAnimatedHeadingProps {
  headingBeforeAnimation?: string;
  headingAfterAnimation?: string;
  stableHeading?: string;
  headingColor?: string;
  alignment?: 'right' | 'left' | 'center';
  typingDelay?: number;
  showCursor?: boolean;
  className?: string;
  animatedHeadings?: { heading: string }[];
  paddingTop?: string;
  paddingBottom?: string;
  sectionBackgroundColor?: string;
}

export const AnimatedHeadingProps: Input[] = [
  { name: 'headingBeforeAnimation', type: 'string' },
  { name: 'headingAfterAnimation', type: 'string' },
  { name: 'stableHeading', type: 'string' },
  { name: 'headingColor', type: 'string' },
  { name: 'alignment', type: 'enum', enum: ['right', 'left', 'center'] },
  { name: 'typingDelay', type: 'string' },
  { name: 'showCursor', type: 'string' },
  { name: 'className', type: 'string' },
  { name: 'animatedHeadings', type: 'list', subFields: [{ name: 'heading', type: 'string', required: true }] },
  { name: 'paddingTop', type: 'string' },
  { name: 'paddingBottom', type: 'string' },
  { name: 'sectionBackgroundColor', type: 'string' },
];

/**
 * Renders the Animated Heading
 *
 * @param {IAnimatedHeadingProps} props
 * @return {ReactElement}
 */
export default function AnimatedHeading({
  headingBeforeAnimation,
  headingAfterAnimation,
  stableHeading,
  headingColor = 'navy',
  alignment,
  typingDelay = 38,
  showCursor = true,
  className,
  animatedHeadings,
  paddingTop = '50px',
  paddingBottom = '50px',
  sectionBackgroundColor = 'navy',
}: IAnimatedHeadingProps): ReactElement {
  const [count, setCount] = useState(1);
  const isMobile = useMediaQuery('(max-width:960px)');

  useEffect(() => {
    setCount(1);
  }, [count]);

  return (
    <div
      className={className}
      style={{
        textAlign: alignment ? alignment : 'center',
        color: ThemeColorMap.get(headingColor),
        fontSize: isMobile ? 26 : 40,
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        backgroundColor: ThemeColorMap.get(sectionBackgroundColor),
      }}
    >
      {headingBeforeAnimation && (
        <StyledComponents.Heading variant="h2">{headingBeforeAnimation + ' '}</StyledComponents.Heading>
      )}
      {stableHeading && <StyledComponents.Heading variant="h2">{stableHeading}</StyledComponents.Heading>}
      {count === 1 && animatedHeadings && (
        <Typist
          className={StyledComponents.Inline.toString()}
          cursor={{ show: showCursor, blink: true }}
          onTypingDone={() => {
            setCount(0);
          }}
          avgTypingDelay={parseInt(typingDelay.toString())}
          stdTypingDelay={10}
        >
          {animatedHeadings.map((ele, i) => (
            <span key={i}>
              <StyledComponents.Heading variant="h2">{ele.heading + ' '}</StyledComponents.Heading>
              <Typist.Backspace
                count={ele.heading.length + 1}
                delay={1500}
              />
            </span>
          ))}
        </Typist>
      )}
      {headingAfterAnimation && (
        <StyledComponents.Heading variant="h2">{headingAfterAnimation + ' '}</StyledComponents.Heading>
      )}
    </div>
  );
}
