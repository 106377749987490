import { ReactElement } from 'react';
import { Input } from '@builder.io/sdk/dist';
import { Box } from '@mui/material';

import PersonCard, { IPersonCardProps } from './PersonCard';
import { ThemeColorMap } from 'constants/themeColors';
import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface IPersonCardsSectionProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  alignment?: 'left' | 'right' | 'center';
  sectionBackgroundColor?: CMSThemeColors;
  personCards: IPersonCardProps[];
}

export const PersonCardsSectionProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'alignment', type: 'enum', enum: ['left', 'right', 'center'] },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  {
    name: 'personCards',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'name', type: 'string', required: true },
      { name: 'designation', type: 'string' },
      { name: 'company', type: 'string' },
      { name: 'image', type: 'file', required: true, allowedFileTypes: CMSAllowedImageTypes },
      { name: 'altText', type: 'string', required: true },
      { name: 'description', type: 'string' },
      { name: 'linkedinUrl', type: 'string' },
    ],
  },
];

/**
 * Renders PorsonCardsSection
 *
 * @param {IPersonCardsSectionProps} props
 * @return {ReactElement}
 */
export default function PersonCardsSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  alignment,
  sectionBackgroundColor = 'light-blue' as CMSThemeColors,
  personCards,
}: IPersonCardsSectionProps): ReactElement {
  return (
    <SectionWrapper bgColor={sectionBackgroundColor}>
      <StyledComponents.Container>
        <StyledComponents.TextContainer>
          {heading && (
            <StyledComponents.Heading
              variant="h2"
              style={{
                color: ThemeColorMap.get(headingColor),
                textAlign: alignment,
                margin: '0px 0px 20px 0px',
              }}
            >
              {heading}
            </StyledComponents.Heading>
          )}
          {description && (
            <StyledComponents.Description
              variant="body1"
              style={{
                color: ThemeColorMap.get(descriptionColor),
                textAlign: alignment,
                margin: '0px 0px 20px 0px',
              }}
            >
              {description}
            </StyledComponents.Description>
          )}
        </StyledComponents.TextContainer>
        <Box
          sx={{
            display: 'grid',
            gridGap: '1rem',
            gridAutoRows: '1fr',
            gridTemplateColumns: {
              md: 'repeat(4,1fr)',
              sm: 'repeat(3,1fr)',
              xs: 'repeat(2,1fr)',
            },
            marginTop: '20px',
            justifyContent: 'center',
          }}
        >
          {personCards.map((cardData, i) => {
            return (
              <Box
                key={i}
                sx={{
                  border: '2px solid rgba(0, 148, 191, 0.25)',
                  borderRadius: {
                    sm: '6px',
                    xs: '12px',
                  },
                  position: 'relative',
                }}
              >
                <PersonCard
                  name={cardData.name}
                  image={cardData.image}
                  altText={cardData.altText}
                  description={cardData.description}
                  company={cardData.company}
                  designation={cardData.designation}
                  linkedinUrl={cardData.linkedinUrl}
                ></PersonCard>
              </Box>
            );
          })}
        </Box>
      </StyledComponents.Container>
    </SectionWrapper>
  );
}
