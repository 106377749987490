import { useState } from 'react';
import router from 'next/router';
import ReactMarkdown from 'react-markdown';

import BackgroundImage from 'components/Common/BackgroundImage';
import { UNICODE, ProductAgeGroup } from 'constants/common';
import { BuyingForModal } from '../BuyingForModal';
import { APP_ROUTES } from 'constants/routes';
import {
  PRODUCT_IMAGE_PLACEHOLDER,
  SELECTED_PRODUCT_KEY,
  RECONDITIONED_AGE_GROUP_KEY,
  PROFILE_TYPE_KEY,
} from 'constants/products.data';
import { ProfileType } from 'types';
import { StyledComponents } from './styled';

const indianCurrencyFormat = new Intl.NumberFormat('en-IN');

export interface IProduct {
  id: string;
  name: string;
  price: number;
  discount: number;
  description: string;
  orderNowCTA: string;
  typeOfProduct: string;
  productImageUrl: string;
  ageGroup: ProductAgeGroup;
}
export interface IProductCardProps {
  product: IProduct;
}

const ProductCard = ({ product }: IProductCardProps) => {
  const { name, price, discount, description, orderNowCTA, productImageUrl, typeOfProduct, ageGroup } = product;

  const [openModal, setOpenModal] = useState<boolean>(false);

  const descriptionCleanHTML = <ReactMarkdown children={description} />;

  const handleContinue = async (profileType: ProfileType) => {
    const Cookies = (await import('universal-cookie')).default;
    const cookies = new Cookies();

    cookies.set(
      SELECTED_PRODUCT_KEY,
      JSON.stringify({
        ...product,
        description: undefined,
      }),
      {
        path: '/',
      },
    );
    cookies.set(RECONDITIONED_AGE_GROUP_KEY, JSON.stringify(ageGroup), {
      path: '/',
    });
    cookies.set(PROFILE_TYPE_KEY, JSON.stringify(profileType), {
      path: '/',
    });

    router.push(APP_ROUTES.ORDER_SUMMARY.getRoute());
  };

  const handleOrderNow = () => {
    if (ageGroup === ProductAgeGroup.GREATER_THAN_FIFTEEN) {
      setOpenModal(true);
      return;
    }

    handleContinue(ProfileType.My_Child);
  };

  const priceInNubmer = parseFloat(price.toString());
  const discountInNumber = parseFloat(discount.toString());

  return (
    <StyledComponents.Container data-testid="product-card">
      <StyledComponents.ImageWrapper>
        <BackgroundImage
          src={productImageUrl || PRODUCT_IMAGE_PLACEHOLDER}
          layout="fill"
        />
      </StyledComponents.ImageWrapper>
      <StyledComponents.MainContent>
        <StyledComponents.ProductName variant="h5">{name}</StyledComponents.ProductName>
        <StyledComponents.PriceBox>
          <StyledComponents.ProductPrice variant="h5">
            {UNICODE.CURRENCY_HTML_SYMBOL} {indianCurrencyFormat.format(price)}
          </StyledComponents.ProductPrice>
          {discountInNumber > 0 && (
            <StyledComponents.ProductPriceWithoutDiscount variant="h6">
              {`${UNICODE.CURRENCY_HTML_SYMBOL} ${indianCurrencyFormat.format(priceInNubmer + discountInNumber)}`}
            </StyledComponents.ProductPriceWithoutDiscount>
          )}
        </StyledComponents.PriceBox>
        <StyledComponents.Bar />
        <StyledComponents.Features>{descriptionCleanHTML}</StyledComponents.Features>
        {typeOfProduct !== 'Addon' && (
          <StyledComponents.Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth={true}
            disableElevation
            onClick={handleOrderNow}
          >
            {orderNowCTA || 'Order Now'}
          </StyledComponents.Button>
        )}
      </StyledComponents.MainContent>
      <BuyingForModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        ageGroup={ageGroup}
        handleContinue={handleContinue}
      />
    </StyledComponents.Container>
  );
};

export default ProductCard;
