import { ReactElement, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { Input } from '@builder.io/sdk';

import { LinkedInIcon } from 'components/Icons/Social/LinkedIn';
import { mobileTheme } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface IPersonCardProps {
  name: string;
  designation?: string;
  company?: string;
  image: string;
  altText: string;
  description?: string;
  linkedinUrl?: string;
}

export const PersonCardProps: Input[] = [
  { name: 'name', type: 'string', required: true },
  { name: 'designation', type: 'string' },
  { name: 'company', type: 'string' },
  { name: 'image', type: 'file', required: true, allowedFileTypes: CMSAllowedImageTypes },
  { name: 'altText', type: 'string', required: true },
  { name: 'description', type: 'string' },
  { name: 'linkedinUrl', type: 'string' },
];

/**
 * Renders PersonCard
 *
 * @param {IPersonCardProps} props
 * @return {ReactElement}
 */
export default function PersonCard({
  name,
  designation,
  company,
  image,
  altText,
  description,
  linkedinUrl,
}: IPersonCardProps): ReactElement {
  const [flipped, setFlipped] = useState(false);

  return (
    <Card
      sx={{
        border: 'none',
        borderRadius: 'inherit',
        height: '100%',
        width: '100%',
        boxShadow: 'none',
        padding: 0,
        color: mobileTheme.palette.navy.main,
      }}
      onMouseLeave={() => {
        setFlipped(false);
      }}
    >
      {flipped ? (
        <>
          <CardContent
            sx={{
              color: `${mobileTheme.palette.navy.main}`,
            }}
          >
            <StyledComponents.Description variant="body2">{description}</StyledComponents.Description>
          </CardContent>
        </>
      ) : (
        <CardContent
          sx={{
            padding: linkedinUrl ? '0px 0px 50px 0px !important' : '0px 0px 10px 0px !important',
            color: `${mobileTheme.palette.navy.main}`,
          }}
          style={{}}
        >
          <StyledComponents.ImageContainer
            onMouseEnter={() => {
              if (!description || !description.trim()) return;
              setFlipped(true);
            }}
            component="img"
            image={image}
            alt={altText}
          />
          <div>
            <StyledComponents.Name
              variant="h5"
              sx={{
                textAlign: 'center',
              }}
            >
              {name}
            </StyledComponents.Name>
            {designation && (
              <StyledComponents.Designation
                variant="body2"
                sx={{ textAlign: 'center' }}
              >
                {designation}
              </StyledComponents.Designation>
            )}
            {linkedinUrl && (
              <StyledComponents.IconContainer>
                <StyledComponents.Icon
                  onClick={() => {
                    window.open(linkedinUrl);
                  }}
                >
                  <LinkedInIcon color="#0099cc" />
                </StyledComponents.Icon>
              </StyledComponents.IconContainer>
            )}
            {company && (
              <StyledComponents.Designation
                variant="body2"
                sx={{ textAlign: 'center' }}
              >
                {company}
              </StyledComponents.Designation>
            )}
          </div>
        </CardContent>
      )}
    </Card>
  );
}
