import { Input } from '@builder.io/sdk';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import CapsuleToggle from 'components/Common/CapsuleToggle';
import { ThemeColorMap } from 'constants/themeColors';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import React, { useState } from 'react';
import SectionWrapper from '../_Common/SectionWrapper';
import { StyledComponents } from './styled';

type InteractiveOceanToggleProps = {
  heading?: string;
  headingColor?: string;
  shortText?: string;
  shortTextColor?: string;
  description?: string;
  descriptionColor?: string;
  sectionBackgroundColor?: string;
  color: CMSThemeColors;
  descriptionSymbolColor?: CMSThemeColors;
  options: {
    rightLabel: string;
    rightDescription?: string;
    rightSymbol: string;
    rightShortDescription?: string;
    rightShortDescriptionSymbol?: string;
    leftLabel: string;
    leftDescription?: string;
    leftSymbol: string;
    leftShortDescription?: string;
    leftShortDescriptionSymbol?: string;
  }[];
};

export const InteractiveOceanToggleProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'shortText', type: 'string' },
  { name: 'shortTextColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'color', type: 'string', enum: CMSThemeColorsList },
  { name: 'descriptionSymbolColor', type: 'string', enum: CMSThemeColorsList },
  {
    name: 'options',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'rightLabel', type: 'string', required: true },
      { name: 'rightDescription', type: 'string' },
      { name: 'rightSymbol', type: 'string', required: true },
      { name: 'rightShortDescription', type: 'string' },
      { name: 'rightShortDescriptionSymbol', type: 'string' },
      { name: 'leftLabel', type: 'string', required: true },
      { name: 'leftDescription', type: 'string' },
      { name: 'leftSymbol', type: 'string', required: true },
      { name: 'leftShortDescription', type: 'string' },
      { name: 'leftShortDescriptionSymbol', type: 'string' },
    ],
  },
];

const SideDescription = ({
  descriptionSymbolColor,
  shortDescriptionSymbol,
  shortDescription,
  isMobile,
  hidden,
  descriptionColor,
}: {
  descriptionSymbolColor: string;
  shortDescriptionSymbol?: string;
  shortDescription?: string;
  isMobile?: boolean;
  hidden?: boolean;
  descriptionColor: string;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems={'center'}
      textAlign={'center'}
      flexDirection={'column'}
      flex={isMobile ? 3 : 1}
      marginTop="10px"
      sx={{ contentVisibility: hidden ? 'hidden' : 'visible' }}
    >
      <Typography
        variant="h5"
        color={ThemeColorMap.get(descriptionSymbolColor)}
      >
        {shortDescriptionSymbol}
      </Typography>
      <Typography
        variant="caption"
        color={ThemeColorMap.get(descriptionColor)}
      >
        {shortDescription}
      </Typography>
    </Box>
  );
};

/**
 *
 * @param {InteractiveOceanToggleProps}}
 * @return {ReactElement}
 */
export default function InteractiveOceanToggle({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor,
  color = 'purple',
  options,
  shortText,
  shortTextColor = 'navy',
  descriptionSymbolColor = 'pink',
}: Readonly<InteractiveOceanToggleProps>) {
  const isMobile = useMediaQuery('(max-width:960px)');

  const [symbols, setSymbols] = useState(options.map((op, idx) => (idx % 2 === 0 ? op.leftSymbol : op.rightSymbol)));

  return (
    <SectionWrapper bgColor={sectionBackgroundColor}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
      >
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'20px'}
          marginBottom={'22px'}
        >
          {heading && (
            <Typography
              variant="h3"
              style={{ textAlign: 'center' }}
              color={ThemeColorMap.get(headingColor)}
            >
              {heading}
            </Typography>
          )}
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            gap="20px"
            flexWrap="wrap"
          >
            {shortText && (
              <Typography
                variant="h4"
                color={ThemeColorMap.get(shortTextColor)}
              >
                {shortText}
              </Typography>
            )}
            <Typography
              variant="h4"
              color={ThemeColorMap.get(color)}
            >
              {symbols.join('')}
            </Typography>
          </Box>
          {description && <Typography color={ThemeColorMap.get(descriptionColor)}>{description}</Typography>}
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          width={'100%'}
        >
          {options.map((item, index) => {
            const buttons = [
              { label: item.leftLabel, value: item.leftLabel },
              { label: item.rightLabel, value: item.rightLabel },
            ];
            return (
              <Box
                key={index}
                display="flex"
                marginBottom={3}
                position="relative"
                justifyContent={'top'}
                flex={12}
                gap={'10px'}
                width={'100%'}
              >
                <SideDescription
                  descriptionSymbolColor={descriptionSymbolColor}
                  shortDescription={item.leftShortDescription}
                  shortDescriptionSymbol={item.leftShortDescriptionSymbol}
                  isMobile={isMobile}
                  hidden={item.leftSymbol !== symbols[index]}
                  descriptionColor={descriptionColor}
                />
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  position={'static'}
                  flex={isMobile ? 6 : 10}
                  top={0}
                  right={0}
                  left={0}
                >
                  <CapsuleToggle
                    toggleButtons={buttons}
                    color={ThemeColorMap.get(color)}
                    onToggle={(word) => {
                      const updatedSymbols = [...symbols];
                      updatedSymbols[index] = item[word === item.leftLabel ? 'leftSymbol' : 'rightSymbol'];
                      setSymbols(updatedSymbols);
                    }}
                    toggleDisabled={false}
                    defaultActiveButton={index % 2 === 0 ? buttons[0] : buttons[1]}
                  />
                  <Box
                    display="flex"
                    marginTop={'10px'}
                    width={'100%'}
                    gap="20px"
                    color={ThemeColorMap.get(descriptionColor)}
                  >
                    <StyledComponents.ToggleDescription
                      isActive={item.leftSymbol === symbols[index]}
                      hidden={item.leftSymbol !== symbols[index]}
                      placement="left"
                    >
                      {item.leftDescription}
                    </StyledComponents.ToggleDescription>
                    <StyledComponents.ToggleDescription
                      isActive={item.rightSymbol === symbols[index]}
                      hidden={item.rightSymbol !== symbols[index]}
                      placement="right"
                    >
                      {item.rightDescription}
                    </StyledComponents.ToggleDescription>
                  </Box>
                </Box>
                <SideDescription
                  descriptionSymbolColor={descriptionSymbolColor}
                  shortDescription={item.rightShortDescription}
                  shortDescriptionSymbol={item.rightShortDescriptionSymbol}
                  isMobile={isMobile}
                  hidden={item.rightSymbol !== symbols[index]}
                  descriptionColor={descriptionColor}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </SectionWrapper>
  );
}
