import { ReactElement } from 'react';
import Link from 'next/link';
import { CardContent, Typography } from '@mui/material';

import { ThemeColorMap } from 'constants/themeColors';
import { StyledComponents } from './styled';

export interface ITextCardProps {
  title: string;
  titleColor?: string;
  description?: string;
  descriptionColor?: string;
  icon?: string;
  image?: string;
  primaryButtonText?: string;
  primaryButtonLink?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  alignment?: 'center' | 'left' | 'right';
}

/**
 *
 * @param {ITextCardProps} props
 * @return {ReactElement}
 */
export default function TextCard({
  title,
  titleColor = 'navy',
  description,
  descriptionColor = 'navy',
  icon,
  image,
  primaryButtonText,
  primaryButtonLink,
  backgroundColor = 'white',
  backgroundImage,
  alignment = 'center',
}: ITextCardProps): ReactElement {
  const getCardBackgroundStyle = () => {
    if (backgroundImage) {
      return {
        backgroundImage: `url(${backgroundImage})`,
      };
    }

    return {
      backgroundColor: ThemeColorMap.get(backgroundColor),
    };
  };

  return (
    <StyledComponents.Card style={getCardBackgroundStyle()}>
      {icon && (
        <StyledComponents.Icon
          component="img"
          alt={title}
          height="220"
          image={icon}
        />
      )}
      {image && (
        <StyledComponents.Image
          component="img"
          alt={title}
          height="220"
          image={image}
        />
      )}
      <CardContent
        style={{
          textAlign: alignment,
          paddingBottom: primaryButtonText && primaryButtonLink ? 0 : 25,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: ThemeColorMap.get(titleColor),
            textTransform: 'uppercase',
            marginTop: image ? 'inherit' : 30,
            lineHeight: 1.5,
          }}
        >
          {title}
        </Typography>
        {description && (
          <StyledComponents.Description
            variant="body1"
            style={{
              color: ThemeColorMap.get(descriptionColor),
              marginTop: 16,
              maxHeight: image ? 200 : 390,
            }}
          >
            {description}
          </StyledComponents.Description>
        )}
      </CardContent>
      {primaryButtonText && primaryButtonLink && (
        <StyledComponents.ButtonContainer>
          <Link href={primaryButtonLink}>
            <StyledComponents.Button
              size="small"
              variant="contained"
              color="primary"
              fullWidth={true}
            >
              {primaryButtonText}
            </StyledComponents.Button>
          </Link>
        </StyledComponents.ButtonContainer>
      )}
    </StyledComponents.Card>
  );
}
