import { ReactElement } from 'react';
import Link from 'next/link';
import { Typography, useMediaQuery } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';

import { ThemeColorMap } from 'constants/themeColors';
import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import ResponsiveVideo from 'components/Common/ResponsiveVideo';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { StyledComponents } from './styled';

export interface ITextVideoSectionProps {
  heading: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  videoAlignment?: 'left' | 'right';
  videoUrl: string;
  videoWidth?: string;
  primaryButtonText?: string;
  primaryButtonLink?: string;
}

export const TextVideoSectionProps: Input[] = [
  { name: 'heading', type: 'string', required: true },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  { name: 'videoAlignment', type: 'enum', enum: ['left', 'right'] },
  { name: 'videoUrl', type: 'file', required: true },
  { name: 'videoWidth', type: 'string' },
  { name: 'primaryButtonText', type: 'string' },
  { name: 'primaryButtonLink', type: 'string' },
];

/**
 * Renders TextVideoSection
 *
 * @param {ITextVideoSectionProps} props
 * @return {ReactElement}
 */
export default function TextVideoSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor = 'white',
  sectionPaddingTop,
  sectionPaddingBottom,
  videoAlignment = 'left',
  videoUrl,
  videoWidth = '550px',
  primaryButtonText,
  primaryButtonLink,
}: ITextVideoSectionProps): ReactElement {
  let direction: 'row' | 'column' | 'row-reverse' = videoAlignment === 'right' ? 'row' : 'row-reverse';
  const isMobile = useMediaQuery('(max-width:960px)');
  if (isMobile) {
    direction = 'column';
  }

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      <StyledComponents.Container style={{ flexDirection: direction || 'row' }}>
        <StyledComponents.TextContainer style={{ flex: videoWidth ? 1 : 'auto' }}>
          {heading && (
            <Typography
              variant="h3"
              style={{
                color: ThemeColorMap.get(headingColor),
              }}
            >
              {heading}
            </Typography>
          )}
          {description?.trim() && (
            <StyledComponents.Description
              variant="body1"
              style={{
                color: ThemeColorMap.get(descriptionColor),
              }}
            >
              {description.trim()}
            </StyledComponents.Description>
          )}
          {primaryButtonText && primaryButtonLink && (
            <StyledComponents.ButtonContainer>
              <Link href={primaryButtonLink ?? ''}>
                <StyledComponents.Button
                  style={{
                    textTransform: 'unset',
                  }}
                  variant="contained"
                  color="primary"
                >
                  {primaryButtonText}
                </StyledComponents.Button>
              </Link>
            </StyledComponents.ButtonContainer>
          )}
        </StyledComponents.TextContainer>
        <StyledComponents.VideoContainer style={{ width: videoWidth ? videoWidth : 'auto' }}>
          <ResponsiveVideo url={videoUrl} />
        </StyledComponents.VideoContainer>
      </StyledComponents.Container>
    </SectionWrapper>
  );
}
