import { ReactElement } from 'react';
import { Input } from '@builder.io/sdk/dist';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import SectionHeader from 'components/CMS/_Common/SectionHeader';
import SectionDescription from 'components/CMS/_Common/SectionDescription';
import { CMSThemeColors, CMSThemeColorsList, mobileTheme } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface ILogo {
  image: string;
  altText?: string;
}

export interface ILogoBannerProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundGradient?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  logos: ILogo[];
}

export const LogoBannerProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  {
    name: 'logos',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'image', required: true, type: 'file', allowedFileTypes: CMSAllowedImageTypes },
      { name: 'altText', type: 'string' },
    ],
  },
];

/**
 * Renders Logo Banner
 *
 * @param {ILogoBannerProps} props
 * @return {ReactElement}
 */
export default function LogoBanner({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor = 'white',
  sectionBackgroundGradient,
  sectionPaddingTop,
  sectionPaddingBottom,
  logos,
}: ILogoBannerProps): ReactElement {
  const slickSettings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: mobileTheme.breakpoints.values.xl,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: mobileTheme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: mobileTheme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgGradient={sectionBackgroundGradient}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      {heading && (
        <SectionHeader
          heading={heading}
          headingColor={headingColor}
        />
      )}
      {description && (
        <SectionDescription
          description={description}
          descriptionColor={descriptionColor}
        />
      )}
      <StyledComponents.ImageContainer>
        <StyledComponents.Slider {...slickSettings}>
          {logos.map((logo, index) => {
            return (
              <StyledComponents.Slide key={index}>
                <img
                  src={logo.image}
                  alt={logo.altText}
                />
              </StyledComponents.Slide>
            );
          })}
        </StyledComponents.Slider>
      </StyledComponents.ImageContainer>
    </SectionWrapper>
  );
}
