import { styled } from '@mui/material/styles';
import { Accordion, AccordionProps, Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  AccordionWrapper: styled('div')({
    marginBottom: 12,
  }),
  Accordion: styled(Accordion)<AccordionProps>({
    borderRadius: 12,
  }),
  AccordionHeading: styled(Typography)<TypographyProps>({
    fontFamily: 'Montserrat',
  }),
};
