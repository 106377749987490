import { ReactElement, useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';

import { IProduct } from 'components/Product/ProductCard';
import ProductList from 'components/Product/ProductList';
import { ThemeColorMap } from 'constants/themeColors';
import { StyledComponents } from './styled';

interface IProductsTabsProps {
  productsData: { [key: string]: IProduct[] };
}

const tabsLabel = [
  { tabKey: '5-7', tabLabel: '5-7 years' },
  { tabKey: '8-14', tabLabel: '8-14 years' },
  { tabKey: '>15', tabLabel: '15+ years' },
];

/**
 * Renders Products Section
 *
 * @param {IProductsTabsProps} props
 * @return {ReactElement}
 */
export default function ProductsTabs({ productsData }: IProductsTabsProps): ReactElement {
  const [activeTab, setActiveTab] = useState<string>();
  const [products, setProducts] = useState<IProduct[]>();
  const availableTabKeys = Object.keys(productsData);

  useEffect(() => {
    const tab = availableTabKeys[0];
    setActiveTab(tab);
    setProducts(productsData[tab]);
  }, [productsData]);

  if (!products) return <div></div>;

  return (
    <>
      {availableTabKeys.length > 1 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '30px auto',
          }}
        >
          {tabsLabel.map((tab) => {
            if (availableTabKeys.indexOf(tab.tabKey) < 0) return;
            return (
              <Chip
                label={tab.tabLabel}
                size="medium"
                sx={{
                  fontSize: { sm: '16px', md: '24px' },
                  padding: { sm: '10px 15px', md: '25px 35px' },
                  borderRadius: '50px !important',
                  borderColor: 'white !important',
                  color: 'white !important',
                  margin: '0px 10px',
                  opacity: activeTab === tab.tabKey ? '1' : '0.5',
                  backgroundColor: activeTab === tab.tabKey ? ThemeColorMap.get('blue') + ' !important' : 'transparent',
                }}
                variant="outlined"
                onClick={() => {
                  setActiveTab(tab.tabKey);
                  setProducts(productsData[tab.tabKey]);
                }}
                clickable={true}
              />
            );
          })}
        </Box>
      )}
      {products && (
        <StyledComponents.ProductsMainContent>
          <ProductList products={products} />
        </StyledComponents.ProductsMainContent>
      )}
    </>
  );
}
