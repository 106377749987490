import { ReactElement } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Typography, useMediaQuery } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';

import { ThemeColorMap } from 'constants/themeColors';
import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface ITextImageSectionProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  image: string;
  imageAlt?: string;
  width: string;
  height: string;
  imageAlignment?: 'left' | 'right';
  primaryButtonText?: string;
  primaryButtonLink?: string;
  sectionBackgroundColor?: CMSThemeColors;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
}

export const TextImageSectionProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'image', type: 'file', allowedFileTypes: CMSAllowedImageTypes, required: true },
  { name: 'imageAlt', type: 'string' },
  { name: 'width', type: 'number', required: true },
  { name: 'height', type: 'number', required: true },
  { name: 'imageAlignment', type: 'enum', enum: ['left', 'right'] },
  { name: 'primaryButtonText', type: 'string' },
  { name: 'primaryButtonLink', type: 'string' },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
];

/**
 * Renders Text Image Section
 *
 * @param {ITextImageSectionProps} props
 * @return {ReactElement}
 */
export default function TextImageSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  width = '600',
  height = '400',
  image,
  imageAlt,
  imageAlignment = 'left',
  primaryButtonText,
  primaryButtonLink,
  sectionBackgroundColor = 'white',
  sectionPaddingTop,
  sectionPaddingBottom,
}: ITextImageSectionProps): ReactElement {
  let direction: 'row' | 'column' | 'row-reverse' = imageAlignment === 'right' ? 'row' : 'row-reverse';

  const isMobile = useMediaQuery('(max-width:960px)');

  if (isMobile) {
    direction = 'column';
  }

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      <StyledComponents.Container style={{ flexDirection: direction }}>
        <StyledComponents.TextContainer>
          {heading && (
            <Typography
              variant="h3"
              style={{
                color: ThemeColorMap.get(headingColor),
              }}
            >
              {heading}
            </Typography>
          )}
          {description && (
            <StyledComponents.Description
              variant="body1"
              sx={{
                marginTop: primaryButtonText ? 0 : '20px',
                color: ThemeColorMap.get(descriptionColor || 'navy'),
              }}
            >
              {description?.trim()}
            </StyledComponents.Description>
          )}
          {primaryButtonText && primaryButtonLink && (
            <div>
              <Link href={primaryButtonLink ?? ''}>
                <StyledComponents.Button
                  sx={{
                    textTransform: 'unset',
                  }}
                  variant="contained"
                  color="primary"
                >
                  {primaryButtonText}
                </StyledComponents.Button>
              </Link>
            </div>
          )}
        </StyledComponents.TextContainer>
        <StyledComponents.PictureContainer>
          <Image
            src={image}
            width={width}
            height={height}
            alt={imageAlt || heading}
            layout={isMobile ? 'intrinsic' : 'fixed'}
          />
        </StyledComponents.PictureContainer>
      </StyledComponents.Container>
    </SectionWrapper>
  );
}
