import { styled } from '@mui/material/styles';
import Slider from 'react-slick';

export const StyledComponents = {
  QuotesContainer: styled('div')({
    paddingBottom: 20,
  }),
  Slider: styled(Slider)(({ theme }) => ({
    '& .slick-track': {
      display: 'flex !important',
      justifyContent: 'center',
      gap: 35,
      [theme.breakpoints.down('xl')]: {
        gap: 15,
      },
      [theme.breakpoints.down('md')]: {
        gap: 0,
      },
    },
    '& .slick-slide': {
      height: 'inherit !important',
      paddingTop: '60px',
      [theme.breakpoints.down('xl')]: {
        paddingTop: '50px',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '40px',
      },
      '& div:first-child': {
        height: '100%',
      },
    },
  })),
};
