import { ReactElement } from 'react';

import { StyledComponents } from './styled';

export interface IResponsiveVideoProps {
  url: string;
}

/**
 * Renders ResponsiveVideo
 *
 * @param {IResponsiveVideoProps} props
 * @return {ReactElement}
 */
export default function ResponsiveVideo({ url }: IResponsiveVideoProps): ReactElement {
  return (
    <StyledComponents.VideoWrapper>
      <StyledComponents.VideoIframe src={url} />
    </StyledComponents.VideoWrapper>
  );
}
