import { styled } from '@mui/material/styles';

interface StyledCarouselWrapperProps {
  fullWidth?: boolean;
}

type StyledImageWrapperProps = StyledCarouselWrapperProps;

export const StyledComponents = {
  CarouselWrapper: styled('div', {
    shouldForwardProp: (prop) => !['fullWidth'].includes(prop as string),
  })<StyledCarouselWrapperProps>(({ theme, fullWidth }) => ({
    position: 'relative',
    marginBottom: fullWidth ? 0 : 50,
    marginRight: fullWidth ? 0 : 30,
    marginLeft: fullWidth ? 0 : 30,
    '&.full-width-slider button.slick-arrow': {
      zIndex: 1,
      margin: 0,
    },
    '&.full-width-slider button.slick-arrow.slick-prev': {
      left: 80,
    },
    '&.full-width-slider button.slick-arrow.slick-next': {
      right: 80,
    },
    '&.full-width-slider button::before': {
      color: 'white',
      opacity: 0.4,
    },
    [theme.breakpoints.down('md')]: {
      '&.full-width-slider button::before': {
        fontSize: 25,
      },
      '&.full-width-slider button.slick-arrow.slick-prev': {
        left: 6,
      },
      '&.full-width-slider button.slick-arrow.slick-next': {
        right: 10,
      },
    },
  })),
  ImageWrapper: styled('div', {
    shouldForwardProp: (prop) => !['fullWidth'].includes(prop as string),
  })<StyledImageWrapperProps>(({ fullWidth }) => ({
    marginTop: fullWidth ? 0 : 10,
    marginBottom: fullWidth ? 0 : 15,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: fullWidth ? '100%' : 'auto',
    },
  })),
};
