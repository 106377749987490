import { Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledComponents = {
  Root: styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '100px',
    paddingBottom: '100px',
    width: '100%',
  })),
  Container: styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 50,
    overflow: 'hidden',
    padding: '0px',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      padding: '0px 20px',
    },
  })),
  TextContainer: styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 25,
    minWidth: 400,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      gap: 15,
    },
  })),
  VideoContainer: styled('div')(({ theme }) => ({
    minWidth: 450,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      width: '100% !important',
    },
  })),

  Button: styled(Button)<ButtonProps>(({ theme }) => ({
    minWidth: '206px',
    width: 'auto',
    height: '60px',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '8px',
    [theme.breakpoints.down('md')]: {
      minWidth: '114px',
      height: '47px',
      fontSize: '16px',
      borderRadius: '12px',
      fontWeight: '600',
    },
  })),
  ButtonContainer: styled('div')({
    padding: '5px 0px',
  }),
  Description: styled(Typography)<TypographyProps>(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  })),
};
