import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledComponents = {
  Description: styled(Typography)<TypographyProps>(({ theme }) => ({
    textAlign: 'center',
    margin: 'auto',
    marginBottom: 40,
    width: '90%',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      width: '92%',
      marginBottom: 30,
    },
  })),
};
