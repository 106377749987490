import React, { ReactElement } from 'react';
import { Button } from '@mui/material';
import NextLink from 'next/link';
import { Input } from '@builder.io/sdk/dist';
import { GenleapThemeColorEnum } from 'lib/theme';

export interface ICMSButtonProps {
  title: string;
  link: string;
  openInNewTab?: boolean;
  fullWidth?: boolean;
  variant: 'contained' | 'outlined' | 'text';
  color: keyof typeof GenleapThemeColorEnum;
}

export const CMSButtonProps: Input[] = [
  { name: 'title', type: 'string', required: true },
  { name: 'link', type: 'string', required: true, defaultValue: '/' },
  { name: 'variant', type: 'string', enum: ['contained', 'outlined', 'text'], defaultValue: 'text' },
  { name: 'openInNewTab', type: 'boolean', defaultValue: false },
  { name: 'fullWidth', type: 'boolean', defaultValue: false },
  { name: 'color', type: 'string', enum: Object.keys(GenleapThemeColorEnum) },
];

/**
 * Renders ImageSeparatorSection
 *
 * @param {ICMSButtonProps} props
 * @return {ReactElement}
 */
export default function CMSButton({
  color,
  title,
  link = '/',
  openInNewTab = false,
  fullWidth = false,
  variant,
}: ICMSButtonProps): ReactElement {
  return (
    <NextLink href={link}>
      <Button
        variant={variant}
        color={color}
        href={link}
        target={openInNewTab ? '_blank' : '_self'}
        fullWidth={fullWidth}
      >
        {title}
      </Button>
    </NextLink>
  );
}
