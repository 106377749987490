import { ReactElement } from 'react';
import { Input } from '@builder.io/sdk/dist';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import SectionHeader from 'components/CMS/_Common/SectionHeader';
import SectionDescription from 'components/CMS/_Common/SectionDescription';
import TestimonialCard, { ITestimonialCardProps } from './TestimonialCard';
import { CMSThemeColors, CMSThemeColorsList, mobileTheme } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface ITestimonialCardsSectionProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundGradient?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  fullWidth?: string;
  testimonialCards: ITestimonialCardProps[];
}

export const TestimonialCardsSectionProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  { name: 'fullWidth', type: 'string', enum: ['yes', 'no'] },
  {
    name: 'testimonialCards',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'testimonial', type: 'string', required: true },
      { name: 'description', type: 'string' },
      { name: 'name', type: 'string' },
      { name: 'designation', type: 'string' },
      { name: 'company', type: 'string' },
      { name: 'image', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
      { name: 'altText', type: 'string' },
      { name: 'primaryButtonText', type: 'string' },
      { name: 'primaryButtonLink', type: 'string' },
      { name: 'isFullWidth', type: 'boolean' },
    ],
  },
];

/**
 * Renders Quote Cards Section
 *
 * @param {ITestimonialCardsSectionProps} props
 * @return {ReactElement}
 */
export default function TestimonialCardsSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor = 'white',
  sectionBackgroundGradient,
  sectionPaddingTop,
  sectionPaddingBottom,
  fullWidth = 'yes',
  testimonialCards,
}: ITestimonialCardsSectionProps): ReactElement {
  const isFullWidth = fullWidth?.toLowerCase() === 'yes' || false;

  const getSlidesToShow = () => {
    if (isFullWidth) {
      return 1;
    }
    if (testimonialCards?.length > 3) {
      return 3;
    }

    return testimonialCards?.length;
  };

  const slickSettings = {
    slidesToShow: getSlidesToShow(),
    slidesToScroll: getSlidesToShow(),
    autoplay: false,
    arrows: true,
    dots: true,
    Infinite: false,
    initialSlide: 0,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: mobileTheme.breakpoints.values.lg,
        settings: {
          slidesToShow: isFullWidth ? 1 : 2,
        },
      },
      {
        breakpoint: mobileTheme.breakpoints.values.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgGradient={sectionBackgroundGradient}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      {heading && (
        <SectionHeader
          heading={heading}
          headingColor={headingColor}
        />
      )}
      {description && (
        <SectionDescription
          description={description}
          descriptionColor={descriptionColor}
        />
      )}
      <StyledComponents.QuotesContainer>
        <StyledComponents.Slider {...slickSettings}>
          {testimonialCards.map((testimonialCard, index) => {
            return (
              <TestimonialCard
                {...testimonialCard}
                isFullWidth={isFullWidth}
                key={index}
              />
            );
          })}
        </StyledComponents.Slider>
      </StyledComponents.QuotesContainer>
    </SectionWrapper>
  );
}
