import { ReactElement } from 'react';
import { Input } from '@builder.io/sdk/dist';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import TextCard, { ITextCardProps } from './TextCard';
import SectionHeader from '../_Common/SectionHeader';
import SectionDescription from '../_Common/SectionDescription';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

export interface ITextCardCarouselProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundGradient?: string;
  sectionBackgroundImage?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  textCards: ITextCardProps[];
}

export const TextCardCarouselProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionBackgroundImage', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  {
    name: 'textCards',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'title', type: 'string', required: true },
      { name: 'titleColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'description', type: 'string' },
      { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'icon', type: 'string' },
      { name: 'image', type: 'string' },
      { name: 'primaryButtonText', type: 'string' },
      { name: 'primaryButtonLink', type: 'string' },
      { name: 'backgroundColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'backgroundImage', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
      { name: 'alignment', type: 'enum', enum: ['left', 'right', 'center'] },
    ],
  },
];

/**
 * Renders Text Card Carousel
 *
 * @param {ITextCardCarouselProps} props
 * @return {ReactElement}
 */
export default function TextCardCarousel({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor = 'white',
  sectionBackgroundGradient,
  sectionBackgroundImage,
  sectionPaddingTop,
  sectionPaddingBottom,
  textCards,
}: ITextCardCarouselProps): ReactElement {
  const slickSettings = {
    slidesToShow: textCards?.length > 3 ? 3 : textCards?.length,
    slidesToScroll: textCards?.length > 3 ? 3 : textCards?.length,
    autoplay: false,
    arrows: true,
    dots: true,
    Infinite: false,
    initialSlide: 0,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const mappedCards = textCards.map((props, idx) => (
    <TextCard
      key={idx}
      {...props}
    />
  ));

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgGradient={sectionBackgroundGradient}
      bgImage={sectionBackgroundImage}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      {heading?.trim() && (
        <SectionHeader
          heading={heading.trim()}
          headingColor={headingColor}
        />
      )}
      {description?.trim() && (
        <SectionDescription
          description={description.trim()}
          descriptionColor={descriptionColor}
        />
      )}
      <div style={{ position: 'relative' }}>
        <StyledComponents.Slider {...slickSettings}>{mappedCards}</StyledComponents.Slider>
      </div>
    </SectionWrapper>
  );
}
