import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

export const StyledComponents = {
  Container: styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: 10,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.06)',
    backgroundColor: theme.palette.white.main,
    overflow: 'hidden',
  })),

  ImageWrapper: styled('div')(() => ({
    position: 'relative',
    width: '100%',
    height: 220,
    backgroundColor: 'gray',
    zIndex: 1,
  })),

  MainContent: styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 28px 22px 28px',
    flex: 'auto',
  })),

  ProductName: styled(Typography)(({ theme }) => ({
    color: theme.palette.navy.main,
    textAlign: 'center',
    marginBottom: 7,
    fontWeight: 'bold',
  })),

  PriceBox: styled('div')(() => ({
    display: 'flex',
    margin: 'auto',
    position: 'relative',
    alignItems: 'end',
    gap: 15,
  })),
  ProductPrice: styled(Typography)(({ theme }) => ({
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.navy.main,
  })),
  ProductPriceWithoutDiscount: styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.navy.main,
    textDecoration: 'line-through',
  })),

  Bar: styled('div')(() => ({
    height: 4,
    margin: '15px 0px',
    backgroundColor: 'rgba(128, 204, 230, 0.15)',
  })),

  Features: styled('div')(({ theme }) => ({
    flex: 'auto',
    listStyleType: 'none',
    fontFamily: 'Open Sans',
    fontSize: 15,
    textAlign: 'left',
    color: theme.palette.navy.main,
    marginBottom: 12,

    '& ul': {},
    '& ul li': {
      margin: '6px 0px',
    },
  })),

  Button: styled(Button)(({ theme }) => ({
    height: 58,
    marginTop: 8,
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.white.main,
  })),
};
