import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { Container } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';

import { ThemeColorMap } from 'constants/themeColors';
import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { StyledComponents } from './styled';

interface IHotspot {
  heading: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  backgroundColor?: CMSThemeColors;
  backgroundImage?: string;
  hotspotLink?: string;
}

export interface IHotspotSectionProps {
  sectionBackgroundColor?: CMSThemeColors;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  hotspots: IHotspot[];
}

export const HotspotSectionProps: Input[] = [
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  {
    name: 'hotspots',
    type: 'list',
    required: true,
    defaultValue: [],
    subFields: [
      { name: 'heading', type: 'string', required: true },
      { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'description', type: 'string' },
      { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'backgroundColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'backgroundImage', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
      { name: 'hotspotLink', type: 'string' },
    ],
  },
];

/**
 * Renders HotspotSection
 *
 * @param {IHotspotSectionProps} props
 * @return {ReactElement}
 */
export default function HotspotSection({
  sectionBackgroundColor,
  sectionPaddingTop = '0px',
  sectionPaddingBottom = '0px',
  hotspots,
}: IHotspotSectionProps): ReactElement {
  const router = useRouter();

  const getBackgroundStyle = ({
    backgroundColor = 'white',
    backgroundImage,
  }: {
    backgroundColor?: CMSThemeColors;
    backgroundImage?: string;
  }) => {
    if (backgroundImage) {
      return {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
      };
    }

    return {
      backgroundColor: ThemeColorMap.get(backgroundColor),
    };
  };

  const handleHotspotClick = (hotspotLink: string | undefined): void => {
    if (!hotspotLink) return;

    router.push(hotspotLink);
  };

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor || 'transparent'}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      <Container maxWidth="lg">
        <StyledComponents.Container>
          {hotspots.map(
            (
              { heading, headingColor, description, descriptionColor, backgroundColor, backgroundImage, hotspotLink },
              idx,
            ) => (
              <StyledComponents.Box
                boxShadow={3}
                style={{
                  ...getBackgroundStyle({ backgroundColor, backgroundImage }),
                  cursor: hotspotLink ? 'pointer' : undefined,
                }}
                key={idx}
                onClick={() => handleHotspotClick(hotspotLink)}
              >
                <StyledComponents.TitleWrapper>
                  <StyledComponents.Title
                    variant="h5"
                    style={{ color: ThemeColorMap.get(headingColor || 'navy') }}
                    dangerouslySetInnerHTML={{ __html: heading }}
                  ></StyledComponents.Title>
                  {description && (
                    <StyledComponents.Description
                      variant="h6"
                      style={{
                        color: ThemeColorMap.get(descriptionColor || 'navy'),
                      }}
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></StyledComponents.Description>
                  )}
                </StyledComponents.TitleWrapper>
              </StyledComponents.Box>
            ),
          )}
        </StyledComponents.Container>
      </Container>
    </SectionWrapper>
  );
}
