import React, { PropsWithChildren, ReactElement } from 'react';
import { Container, ContainerProps } from '@mui/material';
import { Input } from '@builder.io/sdk/dist';
import { withChildren } from '@builder.io/react';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';

export type ICMSContainerProps = {
  maxWidth: ContainerProps['maxWidth'];
  backgroundColor: CMSThemeColors;
};

export const CMSContainerProps: Input[] = [
  { name: 'maxWidth', type: 'string', enum: ['xs', 'sm', 'md', 'lg', 'xl'], defaultValue: 'md' },
  { name: 'backgroundColor', type: 'string', enum: CMSThemeColorsList },
];

/**
 * Renders ImageSeparatorSection
 *
 * @param {ICMSContainerProps} props
 * @return {ReactElement}
 */
function CMSContainer({ children, maxWidth, backgroundColor }: PropsWithChildren<ICMSContainerProps>): ReactElement {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        backgroundColor: `${backgroundColor}.main`,
      }}
    >
      {children}
    </Container>
  );
}

export default withChildren(CMSContainer);
