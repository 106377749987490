import { styled } from '@mui/material/styles';
import { Button, ButtonProps, Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  Heading: styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: '28px',
    },
  })),
  Description: styled(Typography)<TypographyProps>(({ theme }) => ({
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  })),
  Container: styled('div')(({ theme }) => ({
    maxWidth: theme.breakpoints.values.lg,
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  })),
  TextContainer: styled('div')({
    width: '100%',
  }),
  VideoContainer: styled('div')({
    width: '100%',
    maxWidth: 800,
  }),
  Frame: styled('div')({
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
  }),
  Button: styled(Button)<ButtonProps>({
    width: '206px',
    height: '60px',
    fontSize: '18px',
    fontWeight: 'bold',
  }),
};
