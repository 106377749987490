import {
  Button,
  ButtonProps,
  Card,
  CardMedia,
  CardActions,
  CardMediaProps,
  CardProps,
  CardActionsProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledCardMediaProps extends CardMediaProps {
  component: string;
  alt: string;
  height: string;
}

export const StyledComponents = {
  Card: styled(Card)<CardProps>(() => ({
    overflow: 'visible',
    margin: '0px 15px 25px 15px',
    position: 'relative',
    backgroundPosition: 'center !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    height: '100%',
    padding: 0,
  })),
  Icon: styled(CardMedia)<StyledCardMediaProps>(() => ({
    position: 'absolute',
    top: '-30px',
    height: '60px !important',
    width: '60px !important',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 3,
  })),
  Image: styled(CardMedia)<StyledCardMediaProps>(() => ({
    borderRadius: '12px 12px 0px 0px',
  })),
  ButtonContainer: styled(CardActions)<CardActionsProps>(() => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  })),
  Button: styled(Button)<ButtonProps>(() => ({
    marginLeft: '6%',
    marginRight: '6%',
    paddingTop: 15,
    paddingBottom: 15,
    top: 35,
    borderRadius: 10,
  })),
  Description: styled(Typography)<TypographyProps>(() => ({
    overflow: 'hidden',
    whiteSpace: 'pre-line',
  })),
};
