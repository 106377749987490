import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

export const StyledComponents = {
  Container: styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: -135,
    [theme.breakpoints.down('xl')]: {
      marginTop: -125,
    },
  })),
  Box: styled(Box)<BoxProps>(({ theme }) => ({
    height: 270,
    width: 270,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: 'none',
    borderRadius: '50%',
    border: '8px solid white',
    [theme.breakpoints.down('xl')]: {
      height: 250,
      width: 250,
    },
  })),
  TitleWrapper: styled('div')({
    textAlign: 'center',
    paddingRight: 20,
    paddingLeft: 20,
    zIndex: 10,
  }),
  Title: styled(Typography)<TypographyProps>({
    textTransform: 'uppercase',
    whiteSpace: 'pre-line',
  }),
  Description: styled(Typography)<TypographyProps>({
    textTransform: 'uppercase',
    whiteSpace: 'pre-line',
  }),
};
