import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledComponents = {
  QuoteContainer: styled('div')(({ theme }) => ({
    position: 'relative',
    padding: '140px 100px 100px 100px',
    color: 'white',
    borderRadius: 12,
    marginTop: 50,
    [theme.breakpoints.down('xl')]: {
      padding: '110px 80px 75px 80px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '70px 28px 45px 28px',
    },
  })),
  Icon: styled('div')(({ theme }) => ({
    width: 104,
    height: 104,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    position: 'absolute',
    top: -52,
    left: '50%',
    transform: 'translateX(-52px)',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: {
      width: 90,
      height: 90,
      top: -45,
      transform: 'translateX(-45px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 76,
      height: 76,
      top: -38,
      transform: 'translateX(-38px)',
    },
  })),
  QuoteText: styled(Typography)<TypographyProps>(({ theme }) => ({
    textAlign: 'center',
    lineHeight: 1.5,
    letterSpacing: 1,
    fontSize: 37,
    fontFamily: 'Montserrat',
    marginBottom: 22,
    [theme.breakpoints.down('xl')]: {
      fontSize: 26,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      fontWeight: 500,
    },
  })),
  QuoteAuthor: styled(Typography)<TypographyProps>(({ theme }) => ({
    textAlign: 'center',
    fontSize: 20,
    color: '#f9f9f9',
    [theme.breakpoints.down('xl')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      fontWeight: 500,
    },
  })),
};
