import { styled } from '@mui/material/styles';
import Slider from 'react-slick';

export const StyledComponents = {
  ImageContainer: styled('div')(({ theme }) => ({
    maxWidth: 1250,
    width: '100%',
    margin: 'auto',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '94%',
    },
  })),
  Slider: styled(Slider)({
    '& .slick-track': {
      display: 'flex',
      justifyContent: 'center',
      gap: 10,
    },
  }),
  Slide: styled('div')({
    '& > img': {
      margin: 'auto',
      border: 'solid 1px rgba(0, 148, 191, 0.25)',
      borderRadius: 6,
      overflow: 'hidden',
      width: 242,
      height: 100,
    },
  }),
};
