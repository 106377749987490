/* eslint-disable require-jsdoc */
import { useRouter } from 'next/router';
import DefaultErrorPage from 'next/error';
import Head from 'next/head';
import React, { useState } from 'react';
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import { GetStaticPropsContext } from 'next';
import 'lib/cms-helper';
import Navbar from 'components/Common/Navbar';
import { CMSService } from 'services/cms';
import Footer from 'components/Common/Footer';
import { getConfig } from 'config';
import SEO from 'components/Common/SEO';
import { getSeoMetaTags } from 'lib/cms-helper';
import { BuyingForModal } from 'components/Product/BuyingForModal';
import { PROFILE_TYPE_KEY, RECONDITIONED_AGE_GROUP_KEY, SELECTED_PRODUCT_KEY } from 'constants/products.data';
import { APP_ROUTES } from 'constants/routes';
import { ProfileType } from 'types';
import { ProductAgeGroup } from 'constants/common';
import { VerifyEmailModal } from 'components/Common/VerifyEmailModal';

// Initialize the Builder SDK with your organization's API Key
// Find the API Key on: https://builder.io/account/settings
builder.init(getConfig().BUILDER_API_KEY);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Page({ page, navbarItems, footerItems }: { [x: string]: any }) {
  const router = useRouter();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [product, setProduct] = useState<{
    [x: string]: unknown;
    ageGroup?: ProductAgeGroup;
    description?: string;
  }>({});
  //  This flag indicates if you are viewing the page in the Builder editor.
  const isPreviewing = useIsPreviewing();

  if (router.isFallback) {
    return <h1>Loading...</h1>;
  }

  //  Add your error page here to return if there are no matching
  //  content entries published in Builder.
  if (!page && !isPreviewing) {
    return <DefaultErrorPage statusCode={404} />;
  }

  const handleContinue = async (profileType: ProfileType) => {
    const Cookies = (await import('universal-cookie')).default;
    const cookies = new Cookies();

    cookies.set(
      SELECTED_PRODUCT_KEY,
      JSON.stringify({
        ...product,
        description: undefined,
      }),
      {
        path: '/',
      },
    );
    cookies.set(RECONDITIONED_AGE_GROUP_KEY, JSON.stringify(product.ageGroup), {
      path: '/',
    });
    cookies.set(PROFILE_TYPE_KEY, JSON.stringify(profileType), {
      path: '/',
    });

    router.push(APP_ROUTES.ORDER_SUMMARY.getRoute());
  };

  const handleOrderNow = (productAgeGroup: string) => {
    if (productAgeGroup === ProductAgeGroup.GREATER_THAN_FIFTEEN) {
      setOpenModal(true);
      return;
    }

    handleContinue(ProfileType.My_Child);
  };

  return (
    <>
      <Head>
        <title>{page?.data.title}</title>
        <meta
          name="description"
          content={page?.data.description}
        />
        <SEO
          metaTags={getSeoMetaTags({
            title: page?.data.title,
            description: page?.data.description,
            ...(page?.data?.pageMetaInformation || {}),
          })}
        />
      </Head>
      <Navbar
        menuItems={navbarItems}
        blendMode={page?.data?.blendNavbar}
      />
      <BuilderComponent
        model="page"
        content={page}
        context={{
          setProduct,
          handleOrderNow,
          router,
        }}
      />
      <Footer
        address={footerItems.contactInfo.address}
        email={footerItems.contactInfo.email}
        phone={footerItems.contactInfo.phone}
        links={footerItems.footerLinks}
        social={footerItems.footerSocialLinks}
        navigationSections={footerItems.navigationSections}
      />
      <BuyingForModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        ageGroup={product.ageGroup!}
        handleContinue={handleContinue}
      />
      <VerifyEmailModal />
    </>
  );
}

export async function getStaticProps({ params }: GetStaticPropsContext<{ slug: string[] }>) {
  const slug = '/' + (params?.slug?.join('/') || '');
  const [page, navbarItems, footerItems] = await Promise.all([
    CMSService.getPageBySlug(slug),
    CMSService.getNavbarItems(),
    CMSService.getFooterItems(),
  ]);

  return {
    props: {
      page: page || null,
      navbarItems,
      footerItems,
    },
    revalidate: 5,
  };
}

export async function getStaticPaths() {
  const pages = await CMSService.getAllPages();

  return {
    paths: pages.map((page) => `${page.data?.url}`),
    fallback: true,
  };
}
