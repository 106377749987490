import { styled } from '@mui/material/styles';

export const StyledComponents = {
  SectionContents: styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    padding: '1rem',

    [theme.breakpoints.down('lg')]: {
      gap: '4rem',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '1rem',
    },

    '& > div': {
      flex: 1,
    },
  })),
};
