import React, { ReactElement } from 'react';

import { StyledComponents } from './styled';

export interface IToggleButton {
  label: string;
  value?: string;
}

interface IToggleButtonProps extends IToggleButton {
  color: string;
  toggleDisabled: boolean;
  showDisabled?: boolean;
  active?: boolean;
  onClick: (value: string) => void;
  style?: React.CSSProperties;
}

/**
 * Renders Toggle Button
 *
 * @param {IToggleButtonProps} props
 * @return {ReactElement}
 */
export default function ToggleButton({
  label,
  value,
  color,
  toggleDisabled,
  active = false,
  onClick,
  style = {},
  showDisabled = false,
}: IToggleButtonProps): ReactElement {
  const handleClick = () => {
    onClick(value || label);
  };

  return (
    <StyledComponents.ToggleButton
      onClick={handleClick}
      style={style}
      color={color}
      toggleDisabled={toggleDisabled}
      active={active}
      showDisabled={showDisabled}
    >
      {label}
    </StyledComponents.ToggleButton>
  );
}
