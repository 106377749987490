import { ReactElement, useState } from 'react';

import { StyledComponents } from './styled';

export interface ITestimonialCardProps {
  testimonial: string;
  description?: string;
  name?: string;
  designation?: string;
  company?: string;
  image?: string;
  altText?: string;
  primaryButtonText?: string;
  primaryButtonLink?: string;
  isFullWidth?: boolean;
}

/**
 * This component renders the Testimonial Card component.
 * @param {ITestimonialCardProps} props
 * @return {ReactElement}
 */
export default function QuoteCard(props: ITestimonialCardProps): ReactElement {
  const {
    testimonial,
    description,
    name,
    designation,
    company,
    image,
    altText,
    primaryButtonText,
    primaryButtonLink,
    isFullWidth,
  } = props;
  const [imageError, setImageError] = useState(false);

  return (
    <StyledComponents.Card>
      {!imageError && (
        <StyledComponents.Image>
          <img
            src={image}
            alt={altText}
            width="100%"
            onError={() => {
              setImageError(true);
            }}
          />
        </StyledComponents.Image>
      )}
      {testimonial && <StyledComponents.Quote variant="h4">{testimonial}</StyledComponents.Quote>}
      {description && <StyledComponents.Description variant="body1">{description}</StyledComponents.Description>}
      {name && <StyledComponents.Name variant="body2">{name}</StyledComponents.Name>}
      {designation && (
        <StyledComponents.Company variant="body2">
          {designation}
          {company ? `, ${company}` : ''}
        </StyledComponents.Company>
      )}
      {!isFullWidth && primaryButtonText && primaryButtonLink && (
        <StyledComponents.Button
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          href={primaryButtonLink}
        >
          {primaryButtonText}
        </StyledComponents.Button>
      )}
    </StyledComponents.Card>
  );
}
