import { styled } from '@mui/material/styles';

import { ThemeColorMap } from 'constants/themeColors';

const white = ThemeColorMap.get('white');

type ContainerProps = {
  color: string;
};

export const StyledComponents = {
  Container: styled('div', {
    shouldForwardProp: (prop) => !['color'].includes(prop as string),
  })<ContainerProps>(({ color }) => ({
    display: 'flex',
    padding: 8,
    borderRadius: 50,
    backgroundColor: white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: ThemeColorMap.get(color) ? `${ThemeColorMap.get(color)}40` : `${color}40`,
  })),
};
