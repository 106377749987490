import { styled } from '@mui/material/styles';

export const StyledComponents = {
  VideoWrapper: styled('div')({
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
  }),
  VideoIframe: styled('iframe')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  }),
};
