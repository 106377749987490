import { ReactElement } from 'react';

import { ThemeColorMap } from 'constants/themeColors';
import { StyledComponents } from './styled';

interface ISectionHeaderProps {
  heading: string;
  headingColor?: string;
}

/**
 * Renders Section Header
 * @param {ISectionHeaderProps} param0
 * @return {ReactElement}
 */
export default function SectionHeader({ heading, headingColor = 'navy' }: ISectionHeaderProps): ReactElement {
  return (
    <StyledComponents.Header
      variant="h3"
      sx={{
        color: ThemeColorMap.get(headingColor),
      }}
    >
      {heading}
    </StyledComponents.Header>
  );
}
