import { ReactElement } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Input } from '@builder.io/sdk/dist';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import SectionHeader from 'components/CMS/_Common/SectionHeader';
import SectionDescription from 'components/CMS/_Common/SectionDescription';
import { ThemeColorMap } from 'constants/themeColors';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { StyledComponents } from './styled';

interface IAccordion {
  title: string;
  titleColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
}

export interface IAccordionSectionProps {
  heading: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundImage?: string;
  sectionBackgroundGradient?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  accordions: IAccordion[];
}

export const AccordionSectionProps: Input[] = [
  { name: 'heading', type: 'string', required: true },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundImage', type: 'string' },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionPaddingTop', type: 'string' },
  { name: 'sectionPaddingBottom', type: 'string' },
  {
    name: 'accordions',
    required: true,
    type: 'list',
    subFields: [
      { name: 'title', type: 'string', required: true },
      { name: 'titleColor', type: 'string', enum: CMSThemeColorsList },
      { name: 'description', type: 'string' },
      { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
    ],
  },
];

/**
 * Renders Accordion Section
 * @param {IAccordionSectionProps} props
 * @return {ReactElement}
 */
export default function AccordionSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor = 'white',
  sectionBackgroundImage,
  sectionBackgroundGradient,
  sectionPaddingTop,
  sectionPaddingBottom,
  accordions,
}: IAccordionSectionProps): ReactElement {
  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgImage={sectionBackgroundImage}
      bgGradient={sectionBackgroundGradient}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      {heading && (
        <SectionHeader
          heading={heading}
          headingColor={headingColor}
        />
      )}
      {description && (
        <SectionDescription
          description={description}
          descriptionColor={descriptionColor}
        />
      )}
      {accordions.map(({ title, description, titleColor = 'navy', descriptionColor = 'navy' }, index) => (
        <StyledComponents.AccordionWrapper key={index}>
          <StyledComponents.Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StyledComponents.AccordionHeading
                variant="body1"
                style={{
                  color: ThemeColorMap.get(titleColor),
                }}
              >
                {title}
              </StyledComponents.AccordionHeading>
            </AccordionSummary>
            {description && (
              <AccordionDetails>
                <Typography
                  variant="body2"
                  style={{
                    color: ThemeColorMap.get(descriptionColor),
                    whiteSpace: 'pre-line',
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></Typography>
              </AccordionDetails>
            )}
          </StyledComponents.Accordion>
        </StyledComponents.AccordionWrapper>
      ))}
    </SectionWrapper>
  );
}
