import { ReactElement, useEffect } from 'react';
import { Input } from '@builder.io/sdk/dist';

import SectionWrapper from 'components/CMS/_Common/SectionWrapper';
import SectionHeader from 'components/CMS/_Common/SectionHeader';
import SectionDescription from 'components/CMS/_Common/SectionDescription';
import ProductsTabs from './ProductsTabs';
import { SELECTED_PRODUCT_KEY, RECONDITIONED_AGE_GROUP_KEY } from 'constants/products.data';
import { CMSThemeColors, CMSThemeColorsList } from 'lib/theme';
import { CMSAllowedImageTypes } from 'constants/common';
import { IProduct } from 'components/Product/ProductCard';

export interface IProductsSectionProps {
  heading?: string;
  headingColor?: CMSThemeColors;
  description?: string;
  descriptionColor?: CMSThemeColors;
  sectionBackgroundColor?: CMSThemeColors;
  sectionBackgroundGradient?: string;
  sectionBackgroundImage?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageData?: any;
}

export const ProductsSectionProps: Input[] = [
  { name: 'heading', type: 'string' },
  { name: 'headingColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'description', type: 'string' },
  { name: 'descriptionColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundColor', type: 'string', enum: CMSThemeColorsList },
  { name: 'sectionBackgroundGradient', type: 'string' },
  { name: 'sectionBackgroundImage', type: 'file', allowedFileTypes: CMSAllowedImageTypes },
  { name: 'sectionPaddingTop', type: 'number' },
  { name: 'sectionPaddingBottom', type: 'number' },
  { name: 'pageData', type: 'object', defaultValue: [] },
];

/**
 * Renders Products Section
 *
 * @param {IProductsSectionProps} props
 * @return {ReactElement}
 */
export default function ProductsSection({
  heading,
  headingColor = 'navy',
  description,
  descriptionColor = 'navy',
  sectionBackgroundColor = 'light-blue' as CMSThemeColors,
  sectionBackgroundGradient,
  sectionBackgroundImage,
  sectionPaddingTop,
  sectionPaddingBottom,
  pageData = [],
}: IProductsSectionProps): ReactElement {
  useEffect(() => {
    const removeSelectedProductFromCookie = async () => {
      const Cookies = (await import('universal-cookie')).default;
      const cookies = new Cookies();

      cookies.remove(SELECTED_PRODUCT_KEY, { path: '/' });
      cookies.remove(RECONDITIONED_AGE_GROUP_KEY, { path: '/' });
    };

    removeSelectedProductFromCookie();
  }, []);

  const productData = pageData.reduce(
    (
      tabsData: {
        [key: string]: IProduct[];
      },
      product: IProduct,
    ) => {
      if (tabsData[product.ageGroup]) {
        tabsData[product.ageGroup].push(product);
      } else {
        tabsData[product.ageGroup] = [product];
      }
      return tabsData;
    },
    {},
  );

  return (
    <SectionWrapper
      bgColor={sectionBackgroundColor}
      bgGradient={sectionBackgroundGradient}
      bgImage={sectionBackgroundImage}
      paddingTop={sectionPaddingTop}
      paddingBottom={sectionPaddingBottom}
    >
      {heading && (
        <SectionHeader
          heading={heading}
          headingColor={headingColor}
        />
      )}
      {description && (
        <SectionDescription
          description={description}
          descriptionColor={descriptionColor}
        />
      )}
      <ProductsTabs productsData={productData} />
    </SectionWrapper>
  );
}
